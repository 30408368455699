import { useMutation, useQueryClient } from "@tanstack/react-query";
import BillingService from "../../../services/BillingService";
import { ADMIN_USER_QUERY_KEY } from "../UserPage";

export const useDeleteSubAccountMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: BillingService.deleteSubAccount,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ADMIN_USER_QUERY_KEY],
      });
    },
  });
};
