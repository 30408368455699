import clsx from "clsx";
import React from "react";
import {
  Box,
  Autocomplete,
  TextField,
  BoxProps,
  Typography,
  Link,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { LinkButton } from "../button/LinkButton";

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: "underline",
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.primary,
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.text.secondary,
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      marginLeft: theme.spacing(2),
    },
  },

  icon: {
    marginLeft: theme.spacing(0.3),
    fontSize: theme.typography.fontSize * 1.5,
    position: "relative",
    top: theme.spacing(-0.2),
    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(2),
    },
  },

  value: {
    fontSize: 14,
    fontWeight: 500,
  },

  autocompleteContainer: {
    width: theme.spacing(32),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },

  textField: {
    "& .MuiInputBase-root": {
      height: theme.typography.pxToRem(36),
      paddingRight: theme.spacing(2.5),
    },
    "& .MuiAutocomplete-endAdornment": {
      top: "50%",
      transform: "translateY(-50%)",
      right: theme.spacing(1),
    },
    "& .MuiOutlinedInput-input": {
      padding: theme.spacing(0.5, 1),
    },
  },

  autocompleteInputRoot: {
    minHeight: theme.typography.pxToRem(36),
  },
}));

export interface GHLAccountLinkProps extends BoxProps {
  links?: string[];
}

export function GHLAccountLink(props: GHLAccountLinkProps) {
  const { className, links, ...other } = props;
  const s = useStyles();

  const handleLinkClick = (
    event: React.SyntheticEvent,
    value: string | null,
  ) => {
    if (value) {
      window.open(value, "_blank", "noopener noreferrer");
    }
  };

  return (
    <Box className={clsx(className)} {...other}>
      {!links || links.length === 0 ? (
        <Typography className={clsx(s.value)}>Disconnected</Typography>
      ) : links.length === 1 ? (
        <Link
          href={links[0]}
          target="_blank"
          rel="noopener noreferrer"
          className={clsx(s.link)}
        >
          <Typography className={clsx(s.value)}>Connected</Typography>
          <OpenInNewIcon className={clsx(s.icon)} />
        </Link>
      ) : (
        <Box className={clsx(s.autocompleteContainer)}>
          <Autocomplete
            options={links}
            getOptionLabel={(option) => option}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {option}
              </Box>
            )}
            onChange={(event, value) => handleLinkClick(event, value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select location"
                variant="outlined"
                size="small"
                className={clsx(s.textField)}
              />
            )}
            classes={{
              inputRoot: clsx(s.autocompleteInputRoot),
            }}
          />
        </Box>
      )}
    </Box>
  );
}
