import clsx from "clsx";
import React from "react";
import {
  TableRow,
  TableRowProps,
  TableCell,
  Box,
  Typography,
  Badge,
  Link,
  IconButton,
  Button,
  Skeleton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import dayjs, { Dayjs } from "dayjs";
import { ExercisesMenu } from "./ExercisesMenu";
import {
  DuplicatedExerciseAssetDto,
  ExerciseAssetsType,
} from "@growth-machine-llc/stridist-api-client";
import { WorkoutDemoVideoPreview } from "../workout/WorkoutDemoVideoPreview";
import { WorkoutExercise } from "../workout/types";
import { ADMIN_USERS_ROUTE } from "../../routes/routes";
import { ArrowForwardRounded } from "@mui/icons-material";
import { EditorProgramContext } from "../new-editor/hooks";
import { WorkoutDrawer } from "../workout/WorkoutDrawer";
import ActivityPreviewExercises from "../activity-preview/ActivityPreviewExercises";
import { useQuery } from "@tanstack/react-query";
import CustomAssetsService from "../../services/CustomAssetsService";
import { REACT_QUERY_NO_CACHING_OPTIONS } from "../../api/ReactQueryConfig";

const useStyles = makeStyles((theme) => ({
  root: {},

  link: {
    textDecoration: "underline",
    color: theme.palette.text.primary,
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.text.secondary,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(2),
    },
  },

  value: {
    fontSize: 14,
    fontWeight: 500,
  },

  subText: {
    color: theme.palette.text.secondary,
    fontWeight: "normal",
  },
}));

export interface ExercisesTableRowProps extends TableRowProps {
  exerciseInfo: DuplicatedExerciseAssetDto;
  type: ExerciseAssetsType;
}

export function ExercisesTableRow(props: ExercisesTableRowProps) {
  const { className, exerciseInfo, type, ...other } = props;
  const s = useStyles();
  const exercise = JSON.parse(exerciseInfo.content) as WorkoutExercise;
  const [exerciseDrawerOpen, setExerciseDrawerOpen] = React.useState(false);
  const [openHistory, setOpenHistory] = React.useState(false);
  const DUPLICATED_EXERCISE_ASSETS_QUERY_KEY = "duplicated-exercise-assets";
  const { data: historyCount, isLoading } = useQuery({
    queryKey: [
      DUPLICATED_EXERCISE_ASSETS_QUERY_KEY,
      {
        coachId: exerciseInfo.coachId,
        exerciseId: exerciseInfo.id,
      },
    ],
    queryFn: () =>
      CustomAssetsService.getDuplicateExercisesAssetsHistory(
        exerciseInfo.id,
        exerciseInfo.legacyAssetId,
      ),
    enabled: openHistory,
    ...REACT_QUERY_NO_CACHING_OPTIONS,
  });
  const LabeledDate = ({ label, date }: { label: string; date: Dayjs }) => (
    <Typography
      variant="caption"
      sx={{ whiteSpace: "nowrap" }}
      className={s.subText}
    >
      <b>{label}:</b> {date ? dayjs(date).format("MMM DD, YYYY") : "-"}
    </Typography>
  );
  return (
    <TableRow className={clsx(s.root, className)} hover {...other}>
      <TableCell component="th" scope="row">
        {exercise.video && (
          <Box sx={{ width: "355px" }}>
            <WorkoutDemoVideoPreview video={exercise.video} />
          </Box>
        )}
      </TableCell>
      <TableCell component="th" scope="row">
        <Box>
          {exercise.title}
          <br />
          <Typography
            variant="caption"
            component="div"
            sx={{ textWrap: "auto" }}
            className={s.subText}
          >
            {exercise.instructions}
          </Typography>
        </Box>
      </TableCell>
      <TableCell align="center">{<b>{exerciseInfo.id}</b>}</TableCell>
      <TableCell align="center">
        {!openHistory && (
          <Box>
            <Button onClick={() => setOpenHistory(true)}>Show</Button>
          </Box>
        )}
        {isLoading && <Skeleton variant="rounded" />}
        {historyCount && <Typography>{historyCount}</Typography>}
      </TableCell>
      <TableCell align="center">
        {exercise.images.length > 0 ? <b>{exercise.images.length}</b> : "-"}
      </TableCell>
      <TableCell align="center">
        <Badge
          badgeContent={exerciseInfo.duplicatesCountByCoach}
          color="error"
        />
      </TableCell>
      <TableCell align="center">
        <ActivityPreviewExercises
          card={exercise}
          index={0}
          exercises={[exercise]}
          showTitle={false}
          textWrap={false}
        />
      </TableCell>
      {type === ExerciseAssetsType.PRIVATE && (
        <TableCell align="center">
          <Link
            href={`${ADMIN_USERS_ROUTE}/${exerciseInfo.coachId}`}
            target="_blank"
            rel="noopener noreferrer"
            className={clsx(s.link)}
          >
            <Typography className={clsx(s.value)}>
              {exerciseInfo.coachId}
            </Typography>
          </Link>
        </TableCell>
      )}
      <TableCell align="center">
        <LabeledDate
          label="Updated"
          date={dayjs(exerciseInfo.lastModified?.toString())}
        />
        <br />
        <LabeledDate
          label="Created"
          date={dayjs(exerciseInfo.created?.toString())}
        />
      </TableCell>
      <TableCell align="center">
        <ExercisesMenu
          exercise={exercise}
          exerciseId={exerciseInfo.id}
          jsonContent={exerciseInfo.content}
        />
        <IconButton
          children={<ArrowForwardRounded />}
          onClick={() => setExerciseDrawerOpen(!exerciseDrawerOpen)}
          size="large"
        />
        <EditorProgramContext.Provider value={{ programId: 0 }}>
          {exerciseDrawerOpen && (
            <WorkoutDrawer
              exercise={exercise}
              onUpdate={(workout) => {
                setExerciseDrawerOpen(false);
              }}
              onCancel={() => setExerciseDrawerOpen(false)}
              onClose={() => setExerciseDrawerOpen(false)}
              onRemove={() => {}}
              onTitleChange={() => {}}
              open={exerciseDrawerOpen}
            />
          )}
          {/* TODO replace WorkoutDrawer with custom asset editor for admin */}
          {/* <WorkoutEditExercise
                  exercise={exerciseAsset.exercise}
                  onUpdate={() => setExerciseDrawerOpen(false)}
                  onCancel={() => setExerciseDrawerOpen(false)}
                  disableSubtitle
                  isAssetLibrary
                /> */}
        </EditorProgramContext.Provider>
      </TableCell>
    </TableRow>
  );
}
