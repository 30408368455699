import clsx from "clsx";
import React from "react";
import { Typography, TypographyProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { getTodayDaysDiff } from "../../utils/date";
import { humanizeDate, humanizeUserPlan } from "../../utils/user";
import { planStatus } from "../../constants";
import dayjs from "dayjs";
import { SubscriptionBriefDto } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.common.black,
  },
}));

export interface CoachSettingsPlanTextSubtitleProps extends TypographyProps {
  plan: string;
  trialExpired: boolean;
  trialExpiredDate: string;
  subscription: SubscriptionBriefDto;
}

export function CoachSettingsPlanTextSubtitle(
  props: CoachSettingsPlanTextSubtitleProps,
) {
  const {
    className,
    plan,
    trialExpired,
    subscription,
    trialExpiredDate,
    ...other
  } = props;
  const s = useStyles();

  const trialDaysDiff = React.useMemo(() => {
    return getTodayDaysDiff(trialExpiredDate);
  }, [trialExpiredDate]);

  const subscriptionDaysDiff = React.useMemo(() => {
    const date = humanizeDate(subscription?.expiresAt?.toDate());
    return subscription?.status === "active" ? (
      <>
        When you change the plan to a new one, it is extended <br /> by{" "}
        {getTodayDaysDiff(date)} days, as the new plan will be partially paid
        from <br /> the balance of the current plan. After the end of{" "}
        {getTodayDaysDiff(date)} days,
        <br /> the amount of the plan will be withdrawn in full and the <br />{" "}
        plan will be extended for 1 month.
      </>
    ) : null;
  }, [subscription]);

  const node = React.useMemo(() => {
    if (!subscription && !trialExpired) {
      return <>Your {humanizeUserPlan(plan)} plan has ended.</>;
    }
    if (subscription?.status === planStatus.CANCELED) {
      return <>Your plan has been canceled on {subscription?.canceledDate}.</>;
    } else if (
      subscription?.cancelAtPeriodEnd &&
      subscription?.canceledDate &&
      subscription?.expiresAt
    ) {
      return (
        <>
          Your {humanizeUserPlan(plan)} plan was canceled on{" "}
          {humanizeDate(subscription.canceledDate)} and <br />
          will expire on {humanizeDate(subscription?.expiresAt?.toDate())}.
        </>
      );
    } else if (
      subscription?.status === planStatus.UNPAID ||
      subscription?.status === planStatus.PAST_DUE ||
      subscription?.status === planStatus.INCOMPLETE_EXPIRED ||
      subscription?.status === planStatus.INCOMPLETE
    ) {
      return <>Please check your card and try again update plan</>;
    } else if (!subscription?.cancelAtPeriodEnd && subscription?.expiresAt) {
      return (
        <>
          <>
            Your {humanizeUserPlan(plan)} plan will be renewed on{" "}
            {humanizeDate(subscription?.expiresAt?.toDate())}
            .<br />
          </>
          {subscriptionDaysDiff}
        </>
      );
    } else if (!plan && trialExpired) {
      return <>The trial period has ended</>;
    } else if (!plan) {
      return (
        <>
          <b>An unlimited 30 day plan is available to you.</b>
          <br />
          To start working with the platform, you need to issue a trial plan
        </>
      );
    } else if (trialExpired === false) {
      return <>Your trial period will end in {trialDaysDiff} days</>;
    }
  }, [subscription, trialDaysDiff, trialExpired, subscriptionDaysDiff]);

  return (
    <Typography
      className={clsx(s.root, className)}
      variant="body1"
      children={node}
      {...other}
    />
  );
}
