import CustomAssetsService from "../../../services/CustomAssetsService";
import { useOptimisticUpdateMutation } from "../../../hooks/useOptimisticUpdateMutation";
import { PaginatedListOfCustomAssetDto } from "@growth-machine-llc/stridist-api-client";
import { InfiniteData } from "@tanstack/react-query";
import { INFINITE_PAGINATED_DATA_UPDATERS } from "../../../utils/optimisticUpdate";
import { COMPONENT_LIBRARY_ASSET_LIST_QUERY_KEY } from "./useCustomAssets";
import { EXERCISE_LIBRARY_ASSET_LIST_QUERY_KEY } from "../../exercise-library/ExerciseLibraryExercisesList";

export function useDeleteCustomAsset(isExercise: boolean) {
  const queryKey = isExercise
    ? EXERCISE_LIBRARY_ASSET_LIST_QUERY_KEY
    : COMPONENT_LIBRARY_ASSET_LIST_QUERY_KEY;
  const mutationFn = isExercise
    ? CustomAssetsService.deleteExerciseCustomAsset
    : CustomAssetsService.deleteCustomAsset;
  return useOptimisticUpdateMutation({
    queryKey: [queryKey],
    mutationFn: mutationFn,
    optimisticUpdater: {
      updateFn: (
        oldData: InfiniteData<PaginatedListOfCustomAssetDto>,
        id: number,
      ) =>
        INFINITE_PAGINATED_DATA_UPDATERS.filterOutItem(
          oldData,
          id,
        ) as InfiniteData<PaginatedListOfCustomAssetDto>,
    },
    invalidateQueryOptions: {
      enabled: true,
      refetchType: "none",
    },
    exactQueryKey: false,
  });
}
