import React from "react";
import clsx from "clsx";
import WorkoutSetTable from "./WorkoutSetTable";
import { Sets, WorkoutSection } from "./types";
import makeStyles from "@mui/styles/makeStyles";
import { colorSystem } from "../../theme";
import { Collapse, Divider, Skeleton, Typography } from "@mui/material";
import { parseWorkoutResultValueJsonToWorkoutSection } from "./utils";
import { useQuery } from "@tanstack/react-query";
import WorkoutResultsService from "../../services/WorkoutResultsService";
import { useCurrentUserId } from "../../hooks/useCurrentUser";
import { REACT_QUERY_NO_CACHING_OPTIONS } from "../../api/ReactQueryConfig";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      marginBottom: 12,
    },
    header: {
      display: "flex",
      color: colorSystem.gray,
      fontWeight: 500,
      marginBottom: 12,
    },
    history: {
      backgroundColor: colorSystem.green2,
      textAlign: "center",
      color: colorSystem.white,
      borderRadius: 4,
      padding: theme.spacing(0.5),
      marginBottom: theme.spacing(2),
    },
  };
});

export const CLIENT_EXERCISE_HISTORY_QUERY_KEY =
  "CLIENT_EXERCISE_HISTORY_QUERY_KEY";

const WorkoutHistoryClient = (props: any) => {
  const { exercise, openHistory } = props;
  const [bestResult, setBestResult] = React.useState(0);
  const s = useStyles();
  const clientId = useCurrentUserId();

  const { data: workoutExerciseResultsData, isLoading } = useQuery({
    queryKey: [
      CLIENT_EXERCISE_HISTORY_QUERY_KEY,
      {
        clientId: clientId,
        exerciseId: exercise.id,
        exerciseTitle: exercise.title,
      },
    ],
    queryFn: () =>
      WorkoutResultsService.getUserExerciseHistory(
        clientId,
        exercise.assetId,
        exercise.title,
        null,
      ),
    enabled: openHistory,
    ...REACT_QUERY_NO_CACHING_OPTIONS,
  });

  const workoutExerciseResults = workoutExerciseResultsData?.items?.map(
    ({ value, ...node }) => ({
      ...node,
      section: parseWorkoutResultValueJsonToWorkoutSection(value),
    }),
  );

  const resultSets = React.useMemo<
    Array<{ sets: Array<Sets>; completedAt: string }>
  >(() => {
    const results = [];
    workoutExerciseResults?.forEach((workoutExerciseResult) =>
      workoutExerciseResult.section.exercises.forEach((i) => {
        if (i.title === exercise.title && Array.isArray(i.result)) {
          results.push({
            sets: i.result,
            completedAt: workoutExerciseResult.activity.completedAt,
          });
        }
      }),
    );
    return results;
  }, [workoutExerciseResults, exercise.title]);

  React.useEffect(() => {
    let bestResult = 0;
    resultSets?.forEach(({ sets }) => {
      if (Array.isArray(sets)) {
        const summ = sets
          .map((i) => (i.checked ? Number(i.weight) ?? 0 : 0))
          .reduce((prev, curr) => +prev + +curr, 0);
        if (summ > bestResult) bestResult = summ;
      }
    });
    setBestResult(bestResult);
  }, [resultSets]);

  //TODO_API_V2_EXERCISE_RESULT: Investigate how to fix issue when сollapse is not working properly and show all sets at once after first loading.

  return (
    <div>
      {isLoading && openHistory ? (
        <Skeleton variant="text" animation="wave" height={30} />
      ) : (
        <>
          {resultSets.length === 0 && !isLoading && openHistory && (
            <Typography>No results...</Typography>
          )}
          {resultSets.length > 0 && !isLoading && (
            <Collapse in={openHistory} timeout={"auto"} unmountOnExit>
              <div
                className={clsx(s.history)}
              >{`Best result ${bestResult}`}</div>
              {resultSets
                .filter(({ sets }) => Array.isArray(sets) && sets.length > 0)
                .map(({ sets, completedAt }, index) => {
                  return (
                    <React.Fragment key={index}>
                      <WorkoutSetTable
                        sets={sets}
                        history
                        exercise={exercise}
                        completedAt={completedAt}
                      />
                      <Divider sx={{ marginBlock: 3 }} />
                    </React.Fragment>
                  );
                })}
            </Collapse>
          )}
        </>
      )}
    </div>
  );
};

export default WorkoutHistoryClient;
