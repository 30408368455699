import { customAssetsClient } from "../api/ApiClients";
import {
  CreateCustomAssetCommand,
  ExerciseAssetsType,
  ICreateCustomAssetCommand,
  IUpdateCustomAssetCommand,
  PaginatedListOfCustomAssetDto,
  UpdateCustomAssetCommand,
} from "@growth-machine-llc/stridist-api-client";
import { CustomAssetType } from "../constants";
import ServiceBase from "./ServiceBase";

class CustomAssetsService extends ServiceBase {
  getCustomAssets(
    pageNumber: number,
    pageSize: number,
    type: CustomAssetType[],
    searchQuery: string = null,
    includeShared: boolean = false,
  ): Promise<PaginatedListOfCustomAssetDto> {
    return customAssetsClient.getCustomAssets(
      pageNumber,
      pageSize,
      type,
      searchQuery,
      includeShared,
    );
  }
  getWorkoutCustomAssets(
    pageNumber: number,
    pageSize: number,
    searchQuery: string = null,
  ): Promise<PaginatedListOfCustomAssetDto> {
    return customAssetsClient.getWorkoutExercises(
      pageNumber,
      pageSize,
      searchQuery,
    );
  }

  createCustomAsset(variables: ICreateCustomAssetCommand) {
    const command = new CreateCustomAssetCommand(variables);
    return customAssetsClient.createCustomAsset(command);
  }

  updateCustomAsset(variables: IUpdateCustomAssetCommand) {
    const command = new UpdateCustomAssetCommand(variables);
    return customAssetsClient.updateCustomAsset(command.id, command);
  }

  deleteCustomAsset(id: number) {
    return customAssetsClient.deleteCustomAsset(id);
  }

  getDuplicatedExercises(
    exerciseAssetsType: ExerciseAssetsType,
    pageNumber: number,
    pageSize: number,
    searchQueryByName?: string,
    searchQueryByCoachId?: number,
  ) {
    return customAssetsClient.getDuplicatedExercises(
      exerciseAssetsType,
      pageNumber,
      pageSize,
      searchQueryByName,
      searchQueryByCoachId,
    );
  }

  adminDeleteExerciseCustomAsset(id: number) {
    return customAssetsClient.adminDeleteExerciseCustomAsset(id);
  }

  deleteExerciseCustomAsset(id: number) {
    return customAssetsClient.deleteExerciseCustomAsset(id);
  }

  deleteAllDuplicateExercises(exerciseAssetsType: ExerciseAssetsType) {
    return customAssetsClient.deleteDuplicatedWorkoutExerciseCustomAssets(
      exerciseAssetsType,
    );
  }

  getDuplicateExercisesAssetsHistory(assetId: number, legacyAssetId?: string) {
    return customAssetsClient.getDuplicatedExerciseAssetsHistory(
      assetId,
      legacyAssetId,
    );
  }
}

export default new CustomAssetsService();
