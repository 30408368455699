import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TextField, Box, Typography } from "@mui/material";
import Skeleton from "@mui/lab/Skeleton";
import { UserRole } from "@growth-machine-llc/stridist-api-client";
import { useQueryParams } from "../../hooks/useQueryParams";
import { UsersTableRow } from "./UsersTableRow";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ADMIN_USERS_ROUTE } from "../../routes/routes";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import FbUsersService from "../../services/FbUsersService";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.spacing(1.5),
    padding: 1,
    boxShadow: theme.shadows[4],
    marginBottom: theme.spacing(3),
    maxWidth: "1200px",
  },

  tabs: {
    "& span": {
      fontWeight: "bold",
      fontSize: 14,
      textTransform: "uppercase",
    },
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.selected.main,
  },

  container: {
    margin: theme.spacing(3, 2),

    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(3, 6),
    },
  },

  searchInput: {
    "& input": {
      fontSize: 15,
      padding: theme.spacing(2),
      minWidth: theme.spacing(50),
    },
    marginBottom: theme.spacing(2),
  },

  summary: {
    margin: theme.spacing(1.5, 0),
    fontWeight: 500,
    fontSize: 16,
  },

  table: {
    minWidth: 650,

    "& th": {
      fontWeight: "bold",
      fontSize: 15,
    },

    "& td": {
      fontWeight: 500,
      fontSize: 14,
    },

    "& tfoot td": {
      borderBottom: "none",
    },
  },
}));

export interface UsersTableProps {}

export const ADMIN_USERS_QUERY_KEY = "admin-users";

export function UsersTable(props: UsersTableProps) {
  const navigate = useNavigate();
  const s = useStyles();
  const [queryParams, setQueryParams] = useQueryParams({
    page: "0",
    perPage: "5",
    search: null,
    role: null,
  });
  const page = parseInt(queryParams.page) || 0;
  const rowsPerPage = parseInt(queryParams.perPage) || 5;

  const [searchParams] = useSearchParams();

  const roleFromParam = searchParams.get("role");
  const selectedRole =
    roleFromParam === null ? UserRole.COACH : Number(roleFromParam);

  const querySearch = queryParams.search || "";
  const [search, setSearch] = useState(querySearch);

  const { data: users, isFetching } = useQuery({
    queryKey: [ADMIN_USERS_QUERY_KEY, queryParams],
    queryFn: () =>
      FbUsersService.getAdminUsers(
        selectedRole,
        page + 1,
        rowsPerPage,
        querySearch,
      ),
    placeholderData: keepPreviousData,
  });

  useEffect(() => {
    if (querySearch !== search) {
      const timer = setTimeout(() => {
        setQueryParams({
          search,
          page: "0",
        });
      }, 300);

      return () => clearTimeout(timer);
    }
  }, [querySearch, search, setQueryParams]);

  const handleChangePage = (event, newPage) => {
    setQueryParams({
      page: newPage.toString(),
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setQueryParams({
      perPage: event.target.value,
      page: "0",
    });
  };

  const handleTabChange = (event, value) => {
    setSearch("");
    setQueryParams({
      role: value,
      page: "0",
      search: null,
    });
    navigate(
      ADMIN_USERS_ROUTE +
        `?page=0&role=${value}&perPage=${queryParams.perPage}`,
    );
  };

  const emptyRows = Array.from(new Array(rowsPerPage));
  return (
    <Paper className={s.root}>
      <Tabs
        className={s.tabs}
        value={selectedRole}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleTabChange}
      >
        <Tab label="Coaches" value={UserRole.COACH} />
        <Tab label="Clients" value={UserRole.CLIENT} />
      </Tabs>

      <Box className={s.container}>
        <TextField
          className={s.searchInput}
          variant="outlined"
          placeholder="Search Records"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />

        <Typography className={s.summary}>
          {users?.totalCount || 0} record{users?.totalCount === 1 ? "" : "s"}{" "}
          found
        </Typography>

        <Table className={s.table} aria-label="simple table">
          {isFetching ? (
            <>
              <TableHead>
                <TableRow>
                  {Array.from({ length: 5 }).map((_, index) => (
                    <TableCell key={index}>
                      <Skeleton />
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {emptyRows.map((_, index) => (
                  <TableRow sx={{ height: 80 }} key={index}>
                    {Array.from({ length: 5 }).map((_, cellIndex) => (
                      <TableCell key={cellIndex} align="center">
                        <Skeleton />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </>
          ) : (
            <>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  {selectedRole === UserRole.COACH && (
                    <>
                      <TableCell align="center">No. Clients</TableCell>
                      <TableCell align="center">No. Programs</TableCell>
                    </>
                  )}
                  <TableCell align="center">Last Login</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.items.map((user) => (
                  <UsersTableRow user={user} key={user.id} />
                ))}

                {!users.items.length && (
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={selectedRole === UserRole.COACH ? 6 : 4}
                    >
                      No users found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </>
          )}
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                colSpan={6}
                count={users?.totalCount || 0}
                rowsPerPage={rowsPerPage}
                page={Number(page)}
                showFirstButton
                showLastButton
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Box>
    </Paper>
  );
}
