import { alpha, Box, Typography, useTheme } from "@mui/material";
import React from "react";
import { ExerciseSetNumberType } from "../../constants";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  count: {
    fontWeight: "bold",
    minWidth: "50px",
    marginRight: theme.spacing(1),
    textAlign: "right",
    color: theme.palette.text.secondary,
    fontSize: 13,
  },
  string: {
    fontWeight: 500,
    fontSize: 13,
  },
  type: {
    fontWeight: "bold",
    textAlign: "right",
    marginLeft: "auto",
    fontSize: 13,
  },
}));

interface IWorkoutSetOverviewItemProps {
  setString: string;
  count: number;
  type: ExerciseSetNumberType;
  indices: number[];
  index: number;
  textWrap?: boolean;
}

const WorkoutSetOverviewItem = (props: IWorkoutSetOverviewItemProps) => {
  const { index, type, setString, count, indices, textWrap } = props;
  const s = useStyles();
  const theme = useTheme();

  const getSetNumberTypeColor = (type) => {
    switch (type) {
      case ExerciseSetNumberType.FAILURE:
        return theme.palette.error.main;
      case ExerciseSetNumberType.DROP_SET:
        return theme.palette.info.main;
      case ExerciseSetNumberType.REGULAR_SET:
        return theme.palette.primary.main;
      case ExerciseSetNumberType.WARM_UP:
        return theme.palette.warning.main;

      default:
        return theme.palette.common.white;
    }
  };

  const getTypeSet = (type, indices: number[]): string => {
    return type === ExerciseSetNumberType.REGULAR_SET
      ? ``
      : type?.[0]?.toUpperCase() || ``;
  };

  return (
    <Box
      key={index}
      sx={{
        display: "flex",
        background:
          type === ExerciseSetNumberType.REGULAR_SET
            ? theme.palette.common.white
            : alpha(getSetNumberTypeColor(type), 0.1),
        px: 1,
        borderRadius: 2,
        textWrap: textWrap ? "wrap" : "nowrap",
      }}
    >
      <Typography className={s.count}>
        {count > 1 ? ` ${count} sets ` : `1 set`}
      </Typography>

      <Typography className={s.string}>{setString.trim()}</Typography>
      <Typography
        className={s.type}
        sx={{
          color: getSetNumberTypeColor(
            type ?? ExerciseSetNumberType.REGULAR_SET,
          ),
        }}
      >
        {getTypeSet(type, indices)}
      </Typography>
    </Box>
  );
};

export default WorkoutSetOverviewItem;
