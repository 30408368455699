import clsx from "clsx";
import React from "react";
import { Box, BoxProps, useMediaQuery } from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";

import { WorkoutMediaGallery } from "../../../dialog/WorkoutMediaGallery";
import { WorkoutExercise } from "../../../workout/types";
import { colorSystem } from "../../../../theme";
import WorkoutSetTable from "../../../workout/WorkoutSetTable";
import WorkoutButton from "../../../workout/WorkoutButton";
import { useCurrentUser } from "../../../../hooks/useCurrentUser";
import { UserRole } from "../../../../constants";
import WorkoutHistoryClientContainer from "../../../workout/WorkoutHistoryClientContainer";
import WorkoutCardMedia from "../../../workout/WorkoutCardMedia";
import { isOverflown } from "../../../../utils/dom";
import { useReadOnly } from "../../hooks";
import { WorkoutExerciseMenu } from "../../../editor/components/menus/WorkoutExerciseMenu";
import HistoryIcon from "../../../../icons/HistoryIcon";
import CloseIcon from "../../../../icons/CloseIcon";
import WorkoutHistoryClient from "../../../workout/WorkoutHistoryClient";

export const getExerciseBoxShadow = (color: string) =>
  `0px 1px 2px ${alpha(color, 0.1)}, 0px 8px 16px ${alpha(color, 0.15)}, 0px -1px 2px ${alpha(color, 0.05)};`;

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: getExerciseBoxShadow(theme.palette.common.black),
    border: "2px solid transparent",
    borderRadius: 12,
    padding: theme.spacing(3, 3.25, 3, 4),
    margin: theme.spacing(3, 0, 5),
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    fontFamily: "Montserrat, sans-serif",
    fontWeight: 500,

    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      marginRight: 0,
      padding: 0,
      boxShadow: "none",
    },

    transition: "border-color 0.3s ease, box-shadow 0.3s ease",
  },

  hasImage: {},

  superset: {
    "&::before": {
      content: "''",
      display: "block",
      position: "absolute",
      width: 4,
      height: theme.spacing(5),
      top: `${Number(theme.spacing(-5).replace("px", "")) - 2}px`,
      left: "calc(50% - 2px)",
      backgroundColor: theme.palette.primary.main,
    },
  },

  container: {
    display: "flex",
    flex: 1,
    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
    },
  },

  content: {
    width: "50%",
    paddingRight: theme.spacing(2.5),
    [theme.breakpoints.down("md")]: {
      width: "100%",
      maxWidth: "100%",
      paddingRight: 0,
    },
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 12,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginLeft: 0,
      display: "block",
    },
  },

  media: {
    width: "50%",
    paddingLeft: theme.spacing(2.5),
    [theme.breakpoints.down("md")]: {
      width: "100%",
      paddingLeft: 0,
    },
  },
  video: {
    display: "flex",
    minHeight: 300,
    marginBottom: 8,
  },

  title: {
    fontSize: 32,
    lineHeight: "39px",
    color: colorSystem.black,
    fontWeight: 700,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: 20,
      lineHeight: "24px",
    },
  },

  more: {
    display: "inline-block",
    position: "relative",
    marginLeft: theme.spacing(0.5),
    visibility: "hidden",
    "$title:hover &": {
      visibility: "visible",
    },
  },

  comment: {
    color: colorSystem.gray,
    fontSize: 15,
    marginBottom: theme.spacing(1.25),
  },

  subtitle: {
    fontSize: 15,
    fontWeight: 500,
    marginBottom: theme.spacing(1.5),
    marginRight: theme.spacing(2),
  },

  instructions: {
    position: "relative",
    color: colorSystem.gray,
    fontSize: 18,
    marginBottom: theme.spacing(2.5),
    lineHeight: "22px",
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
      lineHeight: "20px",
      marginBottom: theme.spacing(1.5),
    },
  },
  instructionsWithPadding: {
    paddingRight: 45,
  },
  instructionsLineClamp: {
    overflow: "hidden",
    "text-overflow": "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 3,
    "line-clamp": 3,
    "-webkit-box-orient": "vertical",
    paddingRight: 45,
  },
  openMore: {
    display: "block",
    paddingRight: 45,
  },
  option: {
    position: "absolute",
    bottom: 0,
    right: 0,
    color: theme.palette.primary.main,
    cursor: "pointer",
    fontSize: 16,
  },

  cover: {
    minWidth: "100%",
    height: 300,
    cursor: "pointer",
    borderRadius: 6,
  },

  videoClassName: {
    maxWidth: "100%",
    minWidth: "100%",
    width: "100%",
    maxHeight: 240,
    [theme.breakpoints.down("md")]: {
      maxHeight: 420,
    },

    "& [data-vjs-player]": {
      paddingTop: "unset",
      height: 240,

      [theme.breakpoints.down("md")]: {
        height: 420,
      },
    },

    "& .vjs-big-play-button": {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
  },

  images: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
  },

  image: {
    borderRadius: 8,
    cursor: "pointer",

    width: 60,
    height: 60,
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),

    [theme.breakpoints.up("md")]: {
      width: 60,
      height: 60,
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
  },

  expandButton: {
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: 700,
    textTransform: "uppercase",
    display: "flex",
    alignItems: "center",

    "& svg": {
      width: 16,
      height: 16,
      marginLeft: theme.spacing(0.5),
    },
  },

  expanded: {
    "& svg": {
      transform: "rotate(-180deg)",
    },
  },

  counter: {
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: theme.palette.common.black,
    borderRadius: "50%",
    fontSize: 14,
    fontWeight: 700,
    height: 24,
    width: 24,
    display: "block",
    textAlign: "center",
    marginRight: theme.spacing(1),
    flexShrink: 0,
    lineHeight: "20px",
  },

  result: {
    marginBottom: theme.spacing(2),
  },

  resultInput: {
    "& input": {
      fontWeight: 500,
      fontSize: 14,
    },

    "& fieldset": {
      borderRadius: theme.spacing(0.5),
    },
  },

  history: {},
  historyButton: {
    paddingRight: 0,
    display: "flex",
    alignItems: "center",
    borderRadius: theme.spacing(0.5),
    height: theme.spacing(4),
    "& svg": {
      width: theme.spacing(2),
      height: theme.spacing(2),
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
      paddingTop: 18,
    },
  },
  historyButtonText: {
    fontSize: 18,
    fontWeight: 500,
    marginLeft: theme.spacing(0.75),
  },
  sets: {
    marginTop: theme.spacing(2),
  },
}));

export interface WorkoutSectionExerciseProps extends Omit<BoxProps, "onClick"> {
  exercise: WorkoutExercise;
  counter?: number;
  onDelete?: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    exercise: WorkoutExercise,
  ) => void;
  onEdit?: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    exercise: WorkoutExercise,
  ) => void;
  onClick?: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    exercise: WorkoutExercise,
  ) => void;
  onUpdate?: (exercise: WorkoutExercise) => void;
  programId?: number;
}

export function WorkoutSectionExercise(props: WorkoutSectionExerciseProps) {
  const {
    className,
    exercise,
    counter,
    onDelete,
    onEdit,
    onClick,
    onUpdate,
    programId,
    ...other
  } = props;
  const s = useStyles();
  const { title, subtitle, instructions, images, sets, video } = exercise;
  const readOnly = useReadOnly();
  const [openMedia, setOpenMedia] = React.useState(false);
  const [selectedMediaIndex, setSelectedMediaIndex] = React.useState(0);
  const user = useCurrentUser();
  const isClient = user.role === UserRole.CLIENT;
  const [openHistory, setOpenHistory] = React.useState(false);
  const [collapsed, setCollapsed] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);

  const { breakpoints } = useTheme();
  const mdSm = useMediaQuery(breakpoints.down("sm"));

  const height = React.useRef<HTMLDivElement>();

  const coverImageIndex = images.findIndex((it) => it.cover);
  const coverImage = images[coverImageIndex];

  React.useEffect(() => {
    const detectCollapse = () => {
      const el = height.current;

      if (el && isOverflown(el)) {
        setCollapsed(true);
      }
    };

    detectCollapse();
  });

  const handleMediaClick = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();

      const {
        dataset: { mediaIndex },
      } = event.currentTarget;

      setOpenMedia(true);
      setSelectedMediaIndex(parseInt(mediaIndex));
    },
    [],
  );

  const handleMediaDialogClose = React.useCallback(() => {
    setOpenMedia(false);
  }, []);

  const handleDelete = React.useCallback(
    (event) => {
      if (onDelete) {
        onDelete(event, exercise);
      }
    },
    [exercise, onDelete],
  );
  const theme = useTheme();

  const handleEdit = React.useCallback(
    (event) => {
      if (onEdit) {
        onEdit(event, exercise);
      }
    },
    [exercise, onEdit],
  );

  const handleClick = React.useCallback(
    (event) => {
      if (onClick) {
        onClick(event, exercise);
      }
    },
    [exercise, onClick],
  );

  const hasImages = images.length > 0;

  return (
    <Box
      className={clsx(s.root, className, {
        [s.superset]: counter > 1,
        [s.hasImage]: hasImages,
      })}
      data-exercise-id={exercise.id}
      onClick={handleClick}
      {...other}
    >
      <Box className={s.container}>
        <div className={s.content}>
          {Boolean(counter) && <div className={s.counter}>{counter}</div>}
          <div className={clsx(s.header)}>
            <div className={clsx(s.title)}>
              <div>{title}</div>{" "}
              {!readOnly && (
                <WorkoutExerciseMenu
                  className={s.more}
                  onDelete={handleDelete}
                  onEdit={handleEdit}
                />
              )}
            </div>
            {isClient && (
              <WorkoutButton
                icon={<HistoryIcon fill={theme.palette.primary.main} />}
                text="History"
                style={s.historyButton}
                styleText={s.historyButtonText}
                onClick={() => setOpenHistory(!openHistory)}
                closeIcon={<CloseIcon fill={theme.palette.primary.main} />}
                isOpen={openHistory}
              />
            )}
          </div>
          <WorkoutHistoryClient exercise={exercise} openHistory={openHistory} />
          {subtitle && <div className={clsx(s.comment)}>{subtitle}</div>}
          <div
            className={clsx(
              s.instructions,
              collapsed && expanded && s.openMore,
              !expanded && s.instructionsLineClamp,
              expanded && s.instructionsWithPadding,
            )}
          >
            <div ref={height}>{instructions}</div>
            {collapsed && (
              <span
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setExpanded(!expanded);
                }}
                className={clsx(s.option)}
              >
                {expanded ? "less" : "more"}
              </span>
            )}
          </div>
          {mdSm && (
            <WorkoutCardMedia
              coverImage={!!coverImage}
              coverImageIndex={coverImageIndex}
              handleMediaClick={handleMediaClick}
              classNameContainer={s.media}
              coverImageUrl={coverImage?.url}
              classNameCoverImage={s.cover}
              hasImages={hasImages}
              images={images}
              classNameHasImagesContainer={s.images}
              classNameHasImagesImage={s.image}
              video={video}
              videoClassName={s.videoClassName}
            />
          )}
          <WorkoutSetTable
            sets={sets}
            exercise={exercise}
            onUpdate={onUpdate}
            readOnly={readOnly}
            isCoach={user.role === "COACH"}
            programId={programId}
            className={mdSm && s.sets}
          />
        </div>
        {!mdSm && (
          <WorkoutCardMedia
            coverImage={!!coverImage}
            coverImageIndex={coverImageIndex}
            handleMediaClick={handleMediaClick}
            classNameContainer={s.media}
            coverImageUrl={coverImage?.url}
            classNameCoverImage={s.cover}
            hasImages={hasImages}
            images={images}
            classNameHasImagesContainer={s.images}
            classNameHasImagesImage={s.image}
            video={video}
            videoClassName={s.videoClassName}
          />
        )}
      </Box>
      {openMedia && (
        <WorkoutMediaGallery
          open
          defaultIndex={selectedMediaIndex}
          onClose={handleMediaDialogClose}
          images={images}
        />
      )}
    </Box>
  );
}
