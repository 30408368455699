import { Crop, PixelCrop } from "react-image-crop";
import { canvasPreview } from "./components/new-editor/elements/canvasPreview";

export const imageToBlob = (imageElement: HTMLImageElement): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement("canvas");
    canvas.width = imageElement.width;
    canvas.height = imageElement.height;
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      reject(new Error("Failed to get canvas context"));
      return;
    }
    ctx.drawImage(imageElement, 0, 0, imageElement.width, imageElement.height);
    canvas.toBlob(
      (blob) => {
        if (!blob) {
          reject(new Error("Canvas is empty"));
          return;
        }
        resolve(blob);
      },
      "image/png",
      1,
    );
  });
};

export const cropImageFromFile = async (
  imageElement: HTMLImageElement,
  pixelCrop: PixelCrop,
): Promise<File> => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement("canvas");
    canvasPreview(imageElement, canvas, pixelCrop);

    canvas.toBlob(
      (blob) => {
        if (!blob) {
          reject(new Error("Canvas is empty"));
          return;
        }
        resolve(new File([blob], "cropped-image.png", { type: "image/png" }));
      },
      "image/png",
      1,
    );
  });
};
