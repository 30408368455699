import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { MenuItem, ListItemText, ListItemIcon } from "@mui/material";
import { usePopupState, bindMenu } from "material-ui-popup-state/hooks";

import { MoreMenuButton } from "../../../button/MoreMenuButton";
import { Menu, MenuProps } from "../../../menu/Menu";
import { ReactComponent as RemoveIcon } from "../../../../icons/Bin.svg";
import { ReactComponent as EditIcon } from "../../../../icons/PencilOutline.svg";
import { ReactComponent as AddIcon } from "../../../../icons/AddCircleOutline.svg";

const useStyles = makeStyles((theme) => ({
  root: {},

  button: {
    height: 32,

    "& svg": {
      color: theme.palette.secondary.main,
      width: 32,
      height: 32,
    },
  },

  active: {
    display: "block !important",
  },
}));

export interface WorkoutExerciseMenuProps extends Omit<MenuProps, "open"> {
  onDelete?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onEdit?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export function WorkoutExerciseMenu(props: WorkoutExerciseMenuProps) {
  const { className, onDelete, onEdit, ...other } = props;
  const s = useStyles();
  const menuState = usePopupState({
    variant: "popover",
    popupId: "workout-exercise",
  });
  const { isOpen } = menuState;

  const handleEdit = React.useCallback(
    (event) => {
      event.stopPropagation();
      menuState.close();

      if (onEdit) {
        onEdit(event);
      }
    },
    [menuState, onEdit],
  );

  const handleDelete = React.useCallback(
    (event) => {
      event.stopPropagation();
      menuState.close();

      if (onDelete) {
        onDelete(event);
      }
    },
    [menuState, onDelete],
  );

  const handleClick = React.useCallback(
    (event) => {
      event.stopPropagation();
      menuState.open(event);
    },
    [menuState],
  );

  return (
    <div
      className={clsx(s.root, className, isOpen && s.active)}
      contentEditable={false}
    >
      <MoreMenuButton
        styleVariant="inverse"
        className={s.button}
        onClick={handleClick}
      />

      {isOpen && (
        <Menu
          {...bindMenu(menuState)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          {...other}
        >
          <MenuItem onClick={handleEdit}>
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem>

          <MenuItem onClick={handleDelete}>
            <ListItemIcon>
              <RemoveIcon />
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
        </Menu>
      )}
    </div>
  );
}
