import { InfiniteData } from "@tanstack/react-query";

interface IPaginatedList<T> {
  items?: T[];
  pageNumber?: number;
  totalPages?: number;
  totalCount?: number;
  hasPreviousPage?: boolean;
  hasNextPage?: boolean;
}

type ItemsUpdater<T, U> = (
  items: T[],
  args: U,
  tempId?: number,
  page?: number,
  totalPages?: number,
) => T[];

export type IdItem = { id?: number };
export type NameItem = { name?: string };

export const createInfinitePaginatedDataUpdater = <T, U>(
  itemsUpdater: ItemsUpdater<T, U>,
) => {
  return (
    data: InfiniteData<IPaginatedList<T>>,
    args: U,
    tempId?: number,
  ): InfiniteData<IPaginatedList<T>> => {
    return {
      ...data,
      pages: data.pages.map((page, pageIndex) => {
        return {
          ...page,
          items: itemsUpdater(
            page.items,
            args,
            tempId,
            pageIndex,
            data.pages.length,
          ),
        };
      }),
    };
  };
};

const filterOutInfinitePaginatedDataItemUpdater = <T extends IdItem>(
  data: InfiniteData<IPaginatedList<T>>,
  id: number,
): InfiniteData<IPaginatedList<T>> => {
  const updaterFn = createInfinitePaginatedDataUpdater<T, void>((items) => {
    return items.filter((item) => item.id !== id);
  });

  return updaterFn(data, undefined);
};

const filterOutInfinitePaginatedDataItemUpdaterByName = <T extends NameItem>(
  data: InfiniteData<IPaginatedList<T>>,
  name: string,
): InfiniteData<IPaginatedList<T>> => {
  const updaterFn = createInfinitePaginatedDataUpdater<T, void>((items) => {
    return items.filter((item) => item.name !== name);
  });

  return updaterFn(data, undefined);
};

const updateInfinitePaginatedDataItemPropertiesUpdater = <T extends IdItem>(
  data: InfiniteData<IPaginatedList<T>>,
  id: number,
  properties: Partial<T> | ((item: T) => Partial<T>),
): InfiniteData<IPaginatedList<T>> => {
  const updaterFn = createInfinitePaginatedDataUpdater<T, void>(
    (items, _args, id) => {
      return items.map((i) =>
        i.id === id
          ? typeof properties === "function"
            ? (properties(i) as T)
            : { ...i, ...properties }
          : i,
      );
    },
  );

  return updaterFn(data, undefined, id);
};

const addInfinitePaginatedDataItemUpdater = <T extends IdItem>(
  data: InfiniteData<IPaginatedList<T>>,
  item: T,
  atPage: number = 0,
  position: "start" | "end" = "start",
): InfiniteData<IPaginatedList<T>> => {
  const updaterFn = createInfinitePaginatedDataUpdater<T, void>(
    (items, _args, _tempId, pageIndex) => {
      if (pageIndex === atPage) {
        return position === "start" ? [item, ...items] : [...items, item];
      }
      return items;
    },
  );

  return updaterFn(data, undefined);
};

export const INFINITE_PAGINATED_DATA_UPDATERS = {
  filterOutItem: filterOutInfinitePaginatedDataItemUpdater,
  filterOutItemByName: filterOutInfinitePaginatedDataItemUpdaterByName,
  updateItemProperties: updateInfinitePaginatedDataItemPropertiesUpdater,
  addItem: addInfinitePaginatedDataItemUpdater,
};
