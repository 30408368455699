import React from "react";
import {
  Dialog,
  DialogProps,
  Box,
  Typography,
  Stack,
  DialogContent,
} from "@mui/material";
import { AppLogo } from "../../app/AppLogo";
import Lottie from "react-lottie";
import animationData from "../../../lotties/confetti-hover-pinch.json";
import { ActionButton } from "../../button/ActionButton";
import BillingsService from "../../../services/BillingsService";
import { useMutation } from "@tanstack/react-query";
import { useCurrentUser } from "../../../hooks/useCurrentUser";
import { Plan, REACTIVATE_PROMO_QUERY } from "../../../constants";
import { stripeAction } from "../../../stripe";
import { isMobileApp } from "../../../utils/mobile";
import { useToastAlert } from "../../app/ToastAlert/ToastAlertProvider";
import { getPlanFromClientsCount } from "../../../utils/user";
import { COACH_CLIENTS_ACTIVE_ROUTE } from "../../../routes/routes";

enum UpgradeStatus {
  SUCCESS = "success",
  ERROR = "error",
}

export function ReactivateCoachDialog(props: DialogProps) {
  const { className, onClose, ...other } = props;
  const currentUser = useCurrentUser();
  const { showToastAlert } = useToastAlert();

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const {
    mutate: createCheckoutSession,
    isPending: createCheckoutSessionInFlight,
    isSuccess: createdCheckoutSession,
  } = useMutation({
    mutationKey: ["create-checkout-session"],
    mutationFn: BillingsService.createCheckoutSession,
  });

  const handleStripeAction = React.useCallback(
    (id) => {
      stripeAction(id, showToastAlert);
    },
    [showToastAlert],
  );

  const reactivateCoach = React.useCallback(() => {
    if (currentUser?.isImpersonating) {
      showToastAlert("error", {
        message:
          "You cannot activate a subscription while impersonating a user.",
      });
      return;
    }

    const planToUse = currentUser.plan
      ? (currentUser.plan as Plan)
      : getPlanFromClientsCount(currentUser.clientsCountNoSample);

    createCheckoutSession(
      {
        plan: planToUse,
        successUrl: `${window.location.origin}${COACH_CLIENTS_ACTIVE_ROUTE}?${REACTIVATE_PROMO_QUERY}=${UpgradeStatus.SUCCESS}`,
        cancelUrl: `${window.location.href}?${REACTIVATE_PROMO_QUERY}=${UpgradeStatus.ERROR}`,
      },
      {
        onSuccess: ({ sessionId }) => {
          return handleStripeAction(sessionId);
        },
      },
    );
  }, [currentUser, createCheckoutSession, stripeAction, showToastAlert]);

  const handleCloseButtonClick = React.useCallback(() => {}, [onClose]);

  return (
    <Dialog
      onClose={handleCloseButtonClick}
      PaperProps={{ sx: { width: 1, maxWidth: 550 } }}
      componentsProps={{
        backdrop: {
          sx: {
            backdropFilter: "blur(3px)",
          },
        },
      }}
      {...other}
    >
      <DialogContent
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          textAlign: "center",
          padding: 3,
          paddingTop: 4,
        }}
      >
        <AppLogo main full size={160} width={160} />
        <Box
          sx={{
            width: "100%",
            maxWidth: "300px",
            height: "auto",
            marginY: 3,
          }}
          display={"flex"}
          justifyContent={"center"}
        >
          <Lottie options={defaultOptions} width={"100%"} />
        </Box>
        <Typography variant="h6" gutterBottom>
          Welcome Back!
        </Typography>
        <Typography
          variant="h6"
          gutterBottom
          sx={{ display: { xs: "none", sm: "block" } }}
        >
          We’re so glad to see you again
        </Typography>
        <Typography variant="body1" gutterBottom>
          {isMobileApp ? "Switch to WEB version" : "Click below"} to re-activate
          your account.
        </Typography>
        {!isMobileApp && !currentUser?.isImpersonating && (
          <Stack sx={{ py: 2 }}>
            <ActionButton
              size="large"
              children="Activate"
              onClick={reactivateCoach}
              disabled={createCheckoutSessionInFlight || createdCheckoutSession}
            />
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
}
