import { loadStripe } from "@stripe/stripe-js";

const PROD_ORIGINS = [
  "https://app.stridist.com",
  "https://new.app.stridist.com",
];

export const STRIPE_MODE = PROD_ORIGINS.includes(window.location.origin)
  ? "live"
  : "test";

const publishableKey =
  STRIPE_MODE === "live"
    ? process.env.REACT_APP_STRIPE_LIVE_PUBLIC_KEY
    : process.env.REACT_APP_STRIPE_TEST_PUBLIC_KEY;

export const initStripe = async () => {
  return await loadStripe(publishableKey);
};

export const stripeAction = async (id, showToastAlert) => {
  try {
    const stripe = await initStripe();
    const result = await stripe.redirectToCheckout({
      sessionId: id,
    });

    if (result.error) {
      showToastAlert("error", {
        message: result.error.message,
      });
    }
  } catch (e) {
    console.error(e);
    showToastAlert("error", {
      message: "Something went wrong. Please try again later.",
    });
  }
};
