import clsx from "clsx";
import React from "react";
import { Button, ButtonProps, LinearProgress } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import {
  AssetType,
  maxUploadFileSize,
  maxVideoUploadFileSize,
  SOMETHING_WENT_WRONG,
} from "../../constants";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { useTranscodeVideo } from "../../hooks/useTranscodeVideo";
import { useNativeDropzone } from "../../utils/device";
import { getTypesByMime } from "../component-library/utils";

import { SchemaElements } from "../editor/normalizers/withSchema";

import { ElementType } from "../editor/types/elements";
import { useCreateCustomAsset } from "./hooks/useCreateCustomAsset";
import { useUploadFile } from "../../hooks/useUploadFile";
import { CustomAssetType } from "@growth-machine-llc/stridist-api-client";
import { useToastAlert } from "../app/ToastAlert/ToastAlertProvider";

const useStyles = makeStyles((theme) => ({
  root: {
    border: "1px solid",
    borderColor: theme.palette.text.secondary,

    borderRadius: 4,
    backgroundColor: theme.palette.selected.light,
    height: theme.spacing(7),
    fontWeight: "bold",
    fontSize: 16,
  },
  progress: {
    marginTop: theme.spacing(0.5),
    borderRadius: theme.spacing(1),
  },
}));

export interface ComponentLibraryAssetUploadButtonProps extends ButtonProps {}
type Media = {
  type: ElementType;
  url: string;
  name: string;
  size: number;
  mimeType: string;
};

export function ComponentLibraryAssetUploadButton(
  props: ComponentLibraryAssetUploadButtonProps,
) {
  const { className, ...other } = props;
  const s = useStyles();
  const me = useCurrentUser();

  const [progress, setProgress] = React.useState<number | null>(null);

  const [uploadFile, , isUploading] = useUploadFile({
    id: me.id,
    refType: "User",
    getAssetType: (file) => getTypesByMime(file.type).type,
    onProgress: (progress) => {
      setProgress(Math.round((progress.loaded / progress.total) * 100));
    },
  });
  const [transcodeVideo, isTranscoding] = useTranscodeVideo();
  const { mutate: createAsset } = useCreateCustomAsset({ isExercise: false });
  const { showToastAlert } = useToastAlert();
  const handleMedia = React.useCallback(
    ({ type, ...attrs }: Media) => {
      const element = SchemaElements.createElement(type, attrs);
      createAsset({
        name: element.name,
        content: JSON.stringify(element),
        assetType: getTypesByMime(element.mimeType)
          .elementType as unknown as CustomAssetType, // TODO_V2_API fix typing
      });
    },
    [createAsset],
  );

  const reportError = React.useCallback(
    () =>
      showToastAlert("error", {
        message: SOMETHING_WENT_WRONG,
      }),
    [showToastAlert],
  );

  const { getRootProps, getInputProps } = useNativeDropzone({
    onDrop: ([file], errors) => {
      if (errors.length) {
        reportError();
        return;
      } else if (!file) {
        return;
      }

      const { name, size, type: mimeType } = file;
      const { elementType, type } = getTypesByMime(mimeType);
      const maxFileSize =
        type === AssetType.LIBRARY_VIDEO
          ? maxVideoUploadFileSize
          : maxUploadFileSize;

      if (file.size > maxFileSize) {
        showToastAlert("error", {
          message: "File size is too big",
        });
        return;
      }

      uploadFile(file)
        .then(async (uploadedFile) => {
          return elementType === ElementType.VIDEO
            ? { ...uploadedFile, url: await transcodeVideo(uploadedFile.url) }
            : uploadedFile;
        })
        .then(({ url }) => {
          handleMedia({
            type: elementType,
            url,
            name,
            size,
            mimeType,
          });
        });
    },
  });

  return (
    <>
      <Button
        className={clsx(s.root, className)}
        fullWidth
        {...(getRootProps() as any)}
        {...other}
        disabled={isUploading || isTranscoding}
      >
        <input type="file" {...getInputProps()} />
        {isUploading
          ? isTranscoding
            ? "Processing..."
            : "Uploading..."
          : "Upload"}
      </Button>
      {isUploading && (
        <LinearProgress
          className={s.progress}
          variant="determinate"
          color="primary"
          value={progress || 0}
        />
      )}
    </>
  );
}
