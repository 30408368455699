import { useCreateCustomAsset } from "../../component-library/hooks/useCreateCustomAsset";
import { ExerciseAsset } from "../../exercise-library/types";
import { CustomAssetType } from "../../../constants";
import { useUpdateCustomAsset } from "../../component-library/hooks/useUpdateCustomAsset";
import { useCurrentUserId } from "../../../hooks/useCurrentUser";

type Options = {
  onCompleted?: (assetId?: number) => void;
};

type SaveExerciseOptions = {
  existedExercise: boolean;
  successMessage?: string;
};

export function useSaveExercise({
  existedExercise,
  successMessage,
}: SaveExerciseOptions) {
  const { mutate: createAsset } = useCreateCustomAsset({
    isExercise: true,
    successMessage,
  });
  const { mutate: updateAsset } = useUpdateCustomAsset({ successMessage });
  const currentUserId = useCurrentUserId();

  return ({ assetId, exercise, userId }: ExerciseAsset, options?: Options) => {
    const content = JSON.stringify(exercise);

    const shouldUpdate = existedExercise && currentUserId === userId;
    return shouldUpdate
      ? updateAsset(
          {
            id: assetId,
            name: exercise.title,
            content,
            assetType: CustomAssetType.WORKOUT_EXERCISE,
          },
          {
            onSuccess: () => {
              if (options.onCompleted) {
                options.onCompleted();
              }
            },
          },
        )
      : createAsset(
          {
            name: exercise.title,
            content,
            assetType: CustomAssetType.WORKOUT_EXERCISE,
          },
          {
            onSuccess: (assetId) => {
              if (options.onCompleted) {
                options.onCompleted(assetId);
              }
            },
          },
        );
  };
}
