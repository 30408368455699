import ErrorPage from "../components/app/ErrorPage";
import { HttpError } from "found";
import {
  INVITE_ROUTE,
  LOGIN_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  NOT_FOUND_ROUTE,
  TERMS_ROUTE,
  PRIVACY_ROUTE,
  SIGN_UP_ROUTE,
  COACH_ROUTE,
  COACH_GROUPS_ROUTE,
  COACH_GROUPS_ACTIVE_ROUTE,
  COACH_GROUPS_ARCHIVED_ROUTE,
  COACH_GROUP_POST_ROUTE,
  COACH_GROUP_MEMBERS_ROUTE,
  COACH_GROUP_SETTINGS_ROUTE,
  COACH_PROGRAMS_ROUTE,
  COACH_PROGRAMS_PUBLISHED_ROUTE,
  COACH_PROGRAMS_DRAFT_ROUTE,
  COACH_PROGRAMS_ARCHIVED_ROUTE,
  COACH_PROGRAMS_FOLDERS_ROUTE,
  COACH_PROGRAM_CURRICULUM_ROUTE,
  COACH_PROGRAM_ENROLLMENTS_ROUTE,
  COACH_PROGRAM_SETTING_ROUTE,
  COACH_PROGRAM_CREATE_ROUTE,
  COACH_CLIENTS_ROUTE,
  COACH_CLIENTS_ACTIVE_ROUTE,
  COACH_CLIENTS_ARCHIVED_ROUTE,
  COACH_CLIENTS_PENDING_ROUTE,
  COACH_CLIENTS_FORMS_ROUTE,
  COACH_CLIENTS_FORM_ROUTE,
  COACH_CLIENT_OVERVIEW_ROUTE,
  COACH_CLIENT_CALENDAR_ROUTE,
  COACH_CLIENT_ACTIVITY_ROUTE,
  COACH_CLIENT_NOTES_ROUTE,
  COACH_CLIENT_PR0GRAMS_ROUTE,
  COACH_CLIENT_PR0GRAM_ROUTE,
  COACH_CLIENT_FORM_ROUTE,
  COACH_CLIENT_SETTINGS_ROUTE,
  COACH_CLIENT_STATS_ROUTE,
  COACH_CLIENT_PROGRESS_ROUTE,
  COACH_ACTIVITY_INBOX_ROUTE,
  COACH_ACTIVITY_ARCHIVED_ROUTE,
  COACH_MESSAGE_ROUTE,
  COACH_CARD_INFORMATION_ROUTE,
  COACH_CLIENT_PORTAL_ROUTE,
  COACH_INTEGRATION_ROUTE,
  COACH_PLAN_ROUTE,
  COACH_PROFILE_ROUTE,
  COACH_THEME_ROUTE,
  COACH_GROUP_DEFAULT_ROUTE,
  COACH_GROUP_ROUTE,
  COACH_CLIENT_PR0GRAM_COMPONENT_ROUTE,
  LOGOUT_ROUTE,
  SIGN_UP_CLIENT,
  SIGN_UP_CLIENT_COMPLETE,
  SIGN_UP_CLIENT_COMPLETE_PHOTO,
  DEFAULT_ROUTE,
  SITEMAP_ROUTE,
  CLIENT_SETTING_PROFILE_ROUTE,
  CLIENT_SETTING_NOTIFICATIONS_ROUTE,
  CLIENT_PROFILE_ROUTE,
  CLIENT_FORM_SLUG_ROUTE,
  CLIENT_HOME_ROUTE,
  CLIENT_ACTIVITY_ROUTE,
  CLIENT_MEAL_LOGGING_ROUTE,
  CLIENT_SUBSCRIPTION_CANCELED_ROUTE,
  CLIENT_TRIAL_EXPIRED_ROUTE,
  CLIENT_PROGRAMS_ACTIVE_ROUTE,
  CLIENT_PROGRAMS_PAST_ROUTE,
  CLIENT_PROGRAMS_UPCOMING_ROUTE,
  CLIENT_PROGRAMS_ACTIVITY_ROUTE,
  CLIENT_PROGRAMS_PROGRAM_ROUTE,
  CLIENT_PROGRAMS_PROGRAM_COMPONENT_ROUTE,
  CLIENT_PORTAL_ROUTE,
  COACH_PROGRAM_PREVIEW_ROUTE,
  COACH_PROGRAM_COMPONENT_ROUTE,
  COACH_PROGRAM_COMPONENT_CLIENT_SUMMARY,
  COACH_ACTIVITY_ROUTE,
  COACH_MESSAGES_ROUTE,
  CLIENT_GROUP_MEMBERS_ROUTE,
  CLIENT_GROUP_POST_ROUTE,
  CLIENT_GROUP_ROUTE,
  CLIENT_PROGRAMS_ROUTE,
  MESSAGES_ROUTE,
  CLIENT_MESSAGE_ROUTE,
  HOME_ROUTE,
  ADMIN_USERS_ROUTE,
  ADMIN_USER_ROUTE,
  ADMIN_ROUTE,
  ACCESS_DENIED_ROUTE,
  COACH_PROGRAM_ROUTE,
  COACH_CLIENT_ROUTE,
  COACH_PROGRAM_DEFAULT_ROUTE,
  COACH_CLIENT_DEFAULT_ROUTE,
  CLIENT_GROUP_DEFAULT_ROUTE,
  PROGRAMS_ROUTE,
  CLIENT_SETTING_ROUTE,
  MEAL_LOGGING_ROUTE,
  BRANDED_LOGIN_ROUTE,
  COACH_BRANDED_APP_ROUTE,
  ADMIN_EXERCISES_ROUTE,
  ENTER_INVITE_CODE_ROUTE,
} from "./routes";
import React, { useEffect } from "react";
import { Route, Navigate, useLocation } from "react-router-dom";
import { CoachProgramsFolderRoute } from "./coach/programs/folder/CoachProgramsFolderRoute";
import { CoachProgramsArchivedRoute } from "./coach/programs/list/CoachProgramsList";
import { CoachProgramsDraftRoute } from "./coach/programs/list/CoachProgramsList";
import { CoachProgramsPublishedRoute } from "./coach/programs/list/CoachProgramsList";
import { CoachProgramCreateRoute } from "./coach/program/create/CoachProgramCreateRoute";
import { CoachProgramCurriculumRoute } from "./coach/program/curriculum/CoachProgramCurriculumRoute";
import { CoachProgramSettingsRoute } from "./coach/program/settings/CoachProgramSettingsRoute";
import { CoachProgramEnrollmentsRoute } from "./coach/program/enrollments/CoachProgramEnrollmentsRoute";
import { ForgotPasswordScreen } from "../components/auth/ForgotPasswordScreen";
import { CoachGroupsListRoute } from "./coach/groups/CoachGroupsListRoute";
import { CoachGroupPostsRoute } from "./coach/group/post/CoachGroupPostsRoute";
import { CoachGroupMembersRoute } from "./coach/group/members/CoachGroupMembersRoute";
import { CoachGroupSettingsRoute } from "./coach/group/settings/CoachGroupSettingsRoute";
import { NavigateWithParam } from "./utils/common";
import { CoachClientsFormsRoute } from "./coach/clients/forms/CoachClientsFormsRoute";
import { CoachClientsActiveRoute, CoachClientsArchivedRoute, CoachClientsPendingRoute } from "./coach/clients/list/CoachClientsListRoute";
import { CoachClientsFormRoute } from "./coach/clients/form/CoachClientsFormRoute";
import { CoachClientOverviewRoute, CoachClientProgressRoute, CoachClientSettingsRoute } from "./coach/client/generic/CoachClientGeneric";
import { CoachClientCalendarRoute } from "./coach/client/calendar/CoachClientCalendarRoute";
import { CoachClientResponsesRoute } from "./coach/client/responses/CoachClientResponsesRoute";
import { CoachClientNotesRoute } from "./coach/client/notes/CoachClientNotesRoute";
import { CoachClientProgramsRoute } from "./coach/client/programs/CoachClientProgramsRoute";
import { CoachClientProgramRoute } from "./coach/client/program/CoachClientProgramRoute";
import { CoachClientFormRoute } from "./coach/client/form/CoachClientFormRoute";
import { CoachClientProgramComponentRoute } from "./coach/client/program/component/CoachClientProgramComponentRoute";
import { LogoutRoute } from "./auth/logout/LogoutRoute";
import { InviteRoute } from "./auth/invite/InviteRoute";
import { SignUpClientRoute } from "./auth/signup-client/client/SignUpClientRoute";
import { SignUpCompleteRoute } from "./auth/signup-client/complete/SignUpCompleteRoute";
import { SignUpCompletePhotoRoute } from "./auth/signup-client/complete-photo/SignUpCompletePhotoRoute";
import LoginRoute from "./auth/login/LoginRoute";
import { CoachActivityArchivedRoute, CoachActivityInboxRoute } from "./coach/activity/inbox/CoachActivityRoute";
import { MessagesRoute } from "./messages/MessagesRoute";
import { CoachSettingsProfileRoute } from "./coach/settings/profile/CoachSettingsProfileRoute";
import { CoachSettingsClientPortalRoute } from "./coach/settings/client-portal/CoachSettingsClientPortalRoute";
import { CoachSettingsThemeRoute } from "./coach/settings/theme/CoachSettingsThemeRoute";
import { CoachSettingsPlanRoute } from "./coach/settings/plan/CoachSettingsPlanRoute";
import { CoachSettingsCardInformationRoute } from "./coach/settings/card-information/CoachSettingsCardInformationRoute";
import { ClientSettingsProfileRoute } from "./client/settings/profile/ClientSettingsProfileRoute";
import { ClientSettingsNotificationRoute } from "./client/settings/notifications/ClientSettingsNotificationRoute";
import { ClientActivityRoute } from "./client/activity/ClientActivityRoute";
import { ClientFormRoute } from "./client/form/ClientFormRoute";
import { ClientHomeRoute } from "./client/home/ClientHomeRoute";
import { ClientProfileRoute } from "./client/profile/profile/ClientProfileRoute";
import ClientTrialCanceledRoute from "./client/trial-expired/ClientTrialCanceledRoute";
import ClientSubscriptionCanceledRoute from "./client/subscription-canceled/ClientSubscriptionCanceledRoute";
import { ClientMealLoggingRoute } from "./client/meal-logging/ClientMealLoggingRoute";
import { ClientProgramsActiveRoute, ClientProgramsPastRoute, ClientProgramsUpcomingRoute } from "./client/programs/list/ClientProgramListRoute";
import { ClientProgramsProgramRoute } from "./client/programs/program/ClientProgramsProgramRoute";
import { ClientProgramsProgramComponentRoute } from "./client/programs/program-component/ClientProgramsProgramComponentRoute";
import { ClientProgramActivityRoute } from "./client/programs/programs-activity/ClientProgramActivityRoute";
import ClientPortalRoute from "./client-portal/ClientPortalRoute";
import SignupRoute from "./auth/signup/SignupRoute";
import { CoachProgramComponentPreviewRoute } from "./coach/programs/preview/component/CoachProgramComponentPreviewRoute";
import { CoachProgramPreviewRoute } from "./coach/programs/preview/program/CoachProgramPreviewRoute";
import { CoachComponentClientSummaryRoute } from "./coach/programs/summary/CoachComponentClientSummaryRoute";
import AppLayoutWrapper from "../wrappers/router/AppLayoutWrapper";
import CoachProgramsListWrapper from "../wrappers/router/coach/CoachProgramsListWrapper";
import ActivityWrapper from "../wrappers/router/common/ActivityWrapper";
import MessagesWrapper from "../wrappers/router/MessagesWrapper";
import CoachClientsWrapper from "../wrappers/router/coach/CoachClientsWrapper";
import CoachGroupsWrapper from "../wrappers/router/coach/CoachGroupsWrapper";
import CommonSuspenseWrapper from "../wrappers/router/client/CommonSuspenseWrapper";
import AppLayoutClient from "../wrappers/router/AppLayoutClient";
import HomeWrapper from "../wrappers/router/client/HomeWrapper";
import { AdminUsersRoute } from "./admin/users/AdminUsersRoute";
import { AdminUserRoute } from "./admin/user/AdminUserRoute";
import AdminWrapper from "../wrappers/router/AdminWrapper";
import dayjs from "dayjs";
import { ISO_DATE_FORMAT } from "../utils/date";
import CoachClientWrapper from "../wrappers/router/coach/CoachClientProfileWrapper";
import CoachProgramsWrapper from "../wrappers/router/coach/CoachProgramsWrapper";
import { CoachGroupRouteWrapper } from "./coach/group/CoachGroupRouteWrapper";
import { CoachBrandedAppRoute } from "./coach/settings/my-branded-app/CoachBrandedAppRoute";
import ErrorBoundaryScreen from "../components/app/ErrorBoundaryScreen";
import { CoachClientProgramsRouteWrapper } from "./coach/client/programs/CoachClientProgramsRouteWrapper";
import { ProgramsComponentRouteWrapper } from "../wrappers/router/common/ProgramsComponentRouteWrapper";
import { ClientArchivedRoute } from "./auth/archived-client/ClientArchivedRoute";
import { AdminExercisesRoute } from "./admin/exercises/AdminExercisesRoute";
import { EnterInviteCodeScreen } from "../components/auth/EnterInviteCodeScreen";

export const NotFoundPage = () => {
  return <ErrorPage error={new HttpError(404)} />;
};

export const AccessDeniedPage = () => {
  return <ErrorPage error={new HttpError(403)} />;
};

const loggedInUserRoutes = (
  <>
    <Route path={LOGIN_ROUTE} element={<Navigate to={DEFAULT_ROUTE} />} />
  </>
);

export const loggedInCoachRoutes = (isBranded: boolean) => (
  <>
    {loggedInUserRoutes}
    <Route path={DEFAULT_ROUTE} element={<Navigate to={COACH_PROGRAMS_PUBLISHED_ROUTE} />} />

    <Route path={COACH_ROUTE} element={<AppLayoutWrapper />}>
      {/* COACH-SETTINGS */}
      <Route path={COACH_ROUTE} element={<ErrorBoundaryScreen />}>
        <Route path={COACH_PROFILE_ROUTE} element={<CoachSettingsProfileRoute />} />
        <Route path={COACH_CLIENT_PORTAL_ROUTE} element={<CoachSettingsClientPortalRoute />} />
        <Route path={COACH_THEME_ROUTE} element={<CoachSettingsThemeRoute />} />
        <Route path={COACH_PLAN_ROUTE} element={<CoachSettingsPlanRoute />} />
        {isBranded && <Route path={COACH_BRANDED_APP_ROUTE} element={<CoachBrandedAppRoute />} />}
        <Route path={COACH_CARD_INFORMATION_ROUTE} element={<CoachSettingsCardInformationRoute />} />
      </Route>

      {/* COACH-GROUPS */}
      <Route path={COACH_GROUPS_ROUTE} element={<ErrorBoundaryScreen />}>
        <Route path={COACH_GROUPS_ROUTE} element={<CoachGroupsWrapper />}>
          <Route index element={<Navigate to={COACH_GROUPS_ACTIVE_ROUTE} />} />
          <Route path={COACH_GROUPS_ACTIVE_ROUTE} element={<CoachGroupsListRoute />} />
          <Route path={COACH_GROUPS_ARCHIVED_ROUTE} element={<CoachGroupsListRoute />} />
          <Route path={COACH_GROUP_ROUTE} element={<CoachGroupRouteWrapper />}>
            <Route path={COACH_GROUP_POST_ROUTE} element={<CoachGroupPostsRoute />} />
            <Route path={COACH_GROUP_MEMBERS_ROUTE} element={<CoachGroupMembersRoute />} />
            <Route path={COACH_GROUP_SETTINGS_ROUTE} element={<CoachGroupSettingsRoute />} />
            <Route path={COACH_GROUP_ROUTE} element={<NavigateWithParam defaultPath={COACH_GROUP_DEFAULT_ROUTE} />} />
          </Route>
        </Route>
      </Route>

      {/* COACH-PROGRAM LIST */}
      <Route path={COACH_ROUTE} element={<ErrorBoundaryScreen />}>
        <Route path={COACH_PROGRAMS_ROUTE} element={<CoachProgramsListWrapper />}>
          <Route index element={<Navigate to={COACH_PROGRAMS_PUBLISHED_ROUTE} />} />
          <Route path={COACH_PROGRAMS_PUBLISHED_ROUTE} element={<CoachProgramsPublishedRoute />} />
          <Route path={COACH_PROGRAMS_DRAFT_ROUTE} element={<CoachProgramsDraftRoute />} />
          <Route path={COACH_PROGRAMS_ARCHIVED_ROUTE} element={<CoachProgramsArchivedRoute />} />
          <Route path={COACH_PROGRAMS_FOLDERS_ROUTE} element={<CoachProgramsFolderRoute />} />
        </Route>
      </Route>

      {/* COACH-PROGRAMS */}
      <Route path={COACH_PROGRAM_ROUTE} element={<CoachProgramsWrapper />}>
        <Route index element={<NavigateWithParam defaultPath={COACH_PROGRAM_DEFAULT_ROUTE} />} />
        <Route path={COACH_PROGRAM_ENROLLMENTS_ROUTE} element={<CoachProgramEnrollmentsRoute />} />
        <Route path={COACH_PROGRAM_CURRICULUM_ROUTE} element={<CoachProgramCurriculumRoute />} />
        {/* TODO <ReactComponent/> vs <Route/> for components */}
        <Route path={COACH_PROGRAM_COMPONENT_ROUTE} element={<CoachProgramComponentPreviewRoute />} />
      </Route>

      {/* COACH-PROGRAM */}
      <Route path={COACH_PROGRAM_SETTING_ROUTE} element={<CoachProgramSettingsRoute />} />
      <Route path={COACH_PROGRAM_CREATE_ROUTE} element={<CoachProgramCreateRoute />} />
      <Route path={COACH_ROUTE} element={<ProgramsComponentRouteWrapper />}>
        <Route path={COACH_PROGRAM_COMPONENT_CLIENT_SUMMARY} element={<CoachComponentClientSummaryRoute />} />
      </Route>

      <Route path={COACH_PROGRAM_PREVIEW_ROUTE} element={<CoachProgramPreviewRoute />} />

      {/* COACH-CLIENTS */}
      <Route path={COACH_CLIENTS_ROUTE} element={<ErrorBoundaryScreen />}>
        <Route path={COACH_CLIENTS_ROUTE} element={<CoachClientsWrapper />}>
          <Route index element={<Navigate to={COACH_CLIENTS_ACTIVE_ROUTE} />} />
          <Route path={COACH_CLIENTS_ACTIVE_ROUTE} element={<CoachClientsActiveRoute />} />
          <Route path={COACH_CLIENTS_ARCHIVED_ROUTE} element={<CoachClientsArchivedRoute />} />
          <Route path={COACH_CLIENTS_PENDING_ROUTE} element={<CoachClientsPendingRoute />} />
          <Route path={COACH_CLIENTS_FORMS_ROUTE} element={<CoachClientsFormsRoute />} />
        </Route>
      </Route>

      {/* COACH-CLIENT */}
      <Route path={COACH_CLIENT_ROUTE} element={<CoachClientWrapper />}>
        <Route index element={<NavigateWithParam defaultPath={COACH_CLIENT_DEFAULT_ROUTE} />} />
        <Route path={COACH_CLIENT_ROUTE} element={<ErrorBoundaryScreen />}>
          <Route path={COACH_CLIENT_OVERVIEW_ROUTE} element={<CoachClientOverviewRoute />} />
          <Route path={COACH_CLIENT_CALENDAR_ROUTE} element={<CoachClientCalendarRoute />} />
          <Route path={COACH_CLIENT_ACTIVITY_ROUTE} element={<CoachClientResponsesRoute />} />
        </Route>
        <Route path={COACH_CLIENT_NOTES_ROUTE} element={<CoachClientNotesRoute />} />
        <Route path={COACH_CLIENT_PR0GRAMS_ROUTE} element={<CoachClientProgramsRouteWrapper />}>
          <Route index element={<CoachClientProgramsRoute />} />
          <Route path={COACH_CLIENT_PR0GRAM_ROUTE} element={<CoachClientProgramRoute />} />
        </Route>
        <Route path={COACH_CLIENT_SETTINGS_ROUTE} element={<CoachClientSettingsRoute />} />
        <Route path={COACH_CLIENT_PROGRESS_ROUTE} element={<CoachClientProgressRoute />} />
      </Route>

      {/* COACH-ACTIVITY*/}
      <Route path={COACH_ACTIVITY_ROUTE} element={<ErrorBoundaryScreen />}>
        <Route path={COACH_ACTIVITY_ROUTE} element={<ActivityWrapper />}>
          <Route index element={<Navigate to={COACH_ACTIVITY_INBOX_ROUTE} />} />
          <Route path={COACH_ACTIVITY_INBOX_ROUTE} element={<CoachActivityInboxRoute />} />
          <Route path={COACH_ACTIVITY_ARCHIVED_ROUTE} element={<CoachActivityArchivedRoute />} />
        </Route>
      </Route>

      <Route path={COACH_ROUTE} element={<ErrorBoundaryScreen />}>
        <Route path={COACH_MESSAGES_ROUTE} element={<MessagesWrapper />}>
          <Route path={COACH_MESSAGE_ROUTE} element={<MessagesRoute />} />
        </Route>
      </Route>
    </Route>

    <Route path={COACH_CLIENT_FORM_ROUTE} element={<CoachClientFormRoute />} />
    <Route path={COACH_CLIENTS_FORM_ROUTE} element={<CoachClientsFormRoute />} />
    <Route path={COACH_CLIENT_PR0GRAM_COMPONENT_ROUTE} element={<CoachClientProgramComponentRoute />} />
  </>
);

export const loggedInClientRoutes = (isClientArchived: boolean) =>
  isClientArchived ? (
    <>
      <Route path={DEFAULT_ROUTE} element={<ClientArchivedRoute />}></Route>
      <Route path={"*"} element={<Navigate to={DEFAULT_ROUTE} />}></Route>
    </>
  ) : (
    <>
      {loggedInUserRoutes}
      <Route path={DEFAULT_ROUTE} element={<Navigate to={CLIENT_HOME_ROUTE} />} />
      <Route path={CLIENT_TRIAL_EXPIRED_ROUTE} element={<ClientTrialCanceledRoute />} />
      <Route path={CLIENT_SUBSCRIPTION_CANCELED_ROUTE} element={<ClientSubscriptionCanceledRoute />} />
      <Route path={CLIENT_FORM_SLUG_ROUTE} element={<ClientFormRoute />} />
      <Route path={CLIENT_PROGRAMS_PROGRAM_ROUTE} element={<ClientProgramsProgramRoute />} />

      <Route path={SIGN_UP_CLIENT_COMPLETE} element={<SignUpCompleteRoute />} />
      <Route path={SIGN_UP_CLIENT_COMPLETE_PHOTO} element={<SignUpCompletePhotoRoute />} />

      <Route path={DEFAULT_ROUTE} element={<AppLayoutClient />}>
        <Route path={DEFAULT_ROUTE} element={<ErrorBoundaryScreen />}>
          <Route path={HOME_ROUTE} element={<HomeWrapper />}>
            <Route path={CLIENT_HOME_ROUTE} element={<ClientHomeRoute />} />
          </Route>
        </Route>

        <Route path={DEFAULT_ROUTE} element={<ErrorBoundaryScreen />}>
          <Route path={CLIENT_ACTIVITY_ROUTE} element={<ActivityWrapper />}>
            <Route path={CLIENT_ACTIVITY_ROUTE} element={<ClientActivityRoute />} />
          </Route>
        </Route>

        <Route path={DEFAULT_ROUTE} element={<CommonSuspenseWrapper maxWidth="lg" />}>
          <Route path={CLIENT_SETTING_PROFILE_ROUTE} element={<ClientSettingsProfileRoute />} />
          <Route path={CLIENT_SETTING_NOTIFICATIONS_ROUTE} element={<ClientSettingsNotificationRoute />} />
          <Route path={CLIENT_MEAL_LOGGING_ROUTE} element={<ClientMealLoggingRoute />} />
          <Route path={MEAL_LOGGING_ROUTE} element={<NavigateWithParam defaultPath={"/" + dayjs().format(ISO_DATE_FORMAT)} />} />
          <Route path={CLIENT_PROFILE_ROUTE} element={<ClientProfileRoute />} />
          <Route path={DEFAULT_ROUTE} element={<ProgramsComponentRouteWrapper />}>
            <Route path={CLIENT_PROGRAMS_ACTIVITY_ROUTE} element={<ClientProgramActivityRoute />} />
            <Route path={CLIENT_PROGRAMS_PROGRAM_COMPONENT_ROUTE} element={<ClientProgramsProgramComponentRoute />} />
          </Route>
          <Route path={CLIENT_SETTING_ROUTE} element={<Navigate to={CLIENT_SETTING_PROFILE_ROUTE} />} />
        </Route>

        {/* CLIENT-PROGRAMS */}
        <Route path={PROGRAMS_ROUTE} element={<ErrorBoundaryScreen />}>
          <Route path={PROGRAMS_ROUTE} element={<CommonSuspenseWrapper maxWidth="lg" />}>
            <Route index element={<Navigate to={CLIENT_PROGRAMS_ACTIVE_ROUTE} />} />
            <Route path={CLIENT_PROGRAMS_ACTIVE_ROUTE} element={<ClientProgramsActiveRoute />} />
            <Route path={CLIENT_PROGRAMS_UPCOMING_ROUTE} element={<ClientProgramsUpcomingRoute />} />
            <Route path={CLIENT_PROGRAMS_PAST_ROUTE} element={<ClientProgramsPastRoute />} />
          </Route>
        </Route>

        {/* CLIENT-GROUP */}
        <Route path={DEFAULT_ROUTE} element={<ErrorBoundaryScreen />}>
          <Route path={CLIENT_GROUP_ROUTE} element={<CommonSuspenseWrapper maxWidth="lg" />}>
            <Route index element={<NavigateWithParam defaultPath={CLIENT_GROUP_DEFAULT_ROUTE} />} />
            <Route path={CLIENT_GROUP_ROUTE} element={<CoachGroupRouteWrapper />}>
              <Route path={CLIENT_GROUP_POST_ROUTE} element={<CoachGroupPostsRoute />} />
              <Route path={CLIENT_GROUP_MEMBERS_ROUTE} element={<CoachGroupMembersRoute />} />
            </Route>
          </Route>
        </Route>

        {/* CLIENT-MESSAGES */}
        <Route path={DEFAULT_ROUTE} element={<ErrorBoundaryScreen />}>
          <Route path={MESSAGES_ROUTE} element={<MessagesWrapper />}>
            <Route path={CLIENT_MESSAGE_ROUTE} element={<MessagesRoute />} />
          </Route>
        </Route>
      </Route>
    </>
  );

export const loggedOutRoutes = (isBrandedApp: boolean) => (
  <>
    <Route path="/" element={<Navigate to={isBrandedApp ? BRANDED_LOGIN_ROUTE : LOGIN_ROUTE} />} />

    <Route path={CLIENT_PORTAL_ROUTE} element={<ClientPortalRoute />} />
    <Route path={SIGN_UP_CLIENT} element={<SignUpClientRoute />} />

    {!isBrandedApp && (
      <>
        <Route path={LOGIN_ROUTE} element={<LoginRoute />} />
        <Route path={SIGN_UP_ROUTE} element={<SignupRoute />} />
        <Route path={ENTER_INVITE_CODE_ROUTE} element={<EnterInviteCodeScreen />} />
        <Route path={FORGOT_PASSWORD_ROUTE} element={<ForgotPasswordScreen />} />
      </>
    )}

    {/* TODO NAVIGATION: Handle not existing pages */}
    <Route path="*" element={<Navigate to={isBrandedApp ? BRANDED_LOGIN_ROUTE : LOGIN_ROUTE} />} />
  </>
);

export const publicRoutes = (
  <>
    {/* TODO no implementation */}
    <Route path={TERMS_ROUTE} element={<>TERMS_ROUTE</>} />
    <Route path={PRIVACY_ROUTE} element={<>PRIVACY_ROUTE</>} />

    <Route path={INVITE_ROUTE} element={<InviteRoute />} />

    <Route path={NOT_FOUND_ROUTE} element={<NotFoundPage />} />
    <Route path={ACCESS_DENIED_ROUTE} element={<AccessDeniedPage />} />
    <Route path={LOGOUT_ROUTE} element={<LogoutRoute />} />
  </>
);

export const adminRoutes = (
  <>
    <Route path={ADMIN_ROUTE} element={<AdminWrapper />}>
      <Route path={ADMIN_USERS_ROUTE} element={<AdminUsersRoute />} />
      <Route path={ADMIN_USER_ROUTE} element={<AdminUserRoute />} />
      <Route path={ADMIN_EXERCISES_ROUTE} element={<AdminExercisesRoute />} />
    </Route>
  </>
);
