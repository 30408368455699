import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TextField, Box, Typography, Button } from "@mui/material";
import Skeleton from "@mui/lab/Skeleton";
import { useQueryParams } from "../../hooks/useQueryParams";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ADMIN_EXERCISES_ROUTE } from "../../routes/routes";
import { ExercisesTableRow } from "./ExercisesTableRow";
import { keepPreviousData, useMutation, useQuery } from "@tanstack/react-query";
import CustomAssetsService from "../../services/CustomAssetsService";
import { ExerciseAssetsType } from "@growth-machine-llc/stridist-api-client";
import { ConfirmActionDialog } from "../dialog/ConfirmActionDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.spacing(1.5),
    padding: 1,
    boxShadow: theme.shadows[4],
    marginBottom: theme.spacing(3),
  },

  tabs: {
    "& span": {
      fontWeight: "bold",
      fontSize: 14,
      textTransform: "uppercase",
    },
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.selected.main,
  },

  container: {
    margin: theme.spacing(3, 2),

    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(3, 6),
    },
  },

  searchContainer: {
    display: "flex",
    alignItems: "flex-start",
    gap: theme.spacing(5),
    flexWrap: "wrap",
    [theme.breakpoints.down("md")]: {
      gap: theme.spacing(2),
    },
  },

  searchInput: {
    "& input": {
      fontSize: 15,
      padding: theme.spacing(2),
      minWidth: theme.spacing(50),
    },
    marginBottom: theme.spacing(2),
  },

  summary: {
    margin: theme.spacing(1.5, 0),
    fontWeight: 500,
    fontSize: 16,
  },

  table: {
    tableLayout: "auto",
    minWidth: 650,

    "& th": {
      fontWeight: "bold",
      fontSize: 15,
    },

    "& td": {
      fontWeight: 500,
      fontSize: 14,
    },

    "& tfoot td": {
      borderBottom: "none",
    },
  },
}));

export interface ExercisesTableProps {}

export const ADMIN_EXERCISES_QUERY_KEY = "admin-exercises";

export function ExercisesTable(props: ExercisesTableProps) {
  const navigate = useNavigate();
  const s = useStyles();
  const [queryParams, setQueryParams] = useQueryParams({
    page: "0",
    perPage: "5",
    assetName: null,
    coachId: null,
    type: null,
  });
  const page = parseInt(queryParams.page) || 0;
  const rowsPerPage = parseInt(queryParams.perPage) || 5;
  const [
    confirmDeleteAllDuplicatesByTypeDialogOpen,
    setConfirmDeleteAllDuplicatesByTypeDialogOpen,
  ] = React.useState(false);

  const [searchParams] = useSearchParams();

  const typeFromParam = searchParams.get("type");
  const selectedType =
    typeFromParam === null
      ? ExerciseAssetsType.LIBRARY
      : (typeFromParam as ExerciseAssetsType);

  const querySearchByName = queryParams.assetName || "";
  const [searchByName, setSearchByName] = useState(querySearchByName);

  const querySearchByCoachId = queryParams.coachId || "";
  const [searchByCoachId, setSearchByCoachId] = useState(querySearchByCoachId);

  const { data: exerciseItems, isFetching } = useQuery({
    queryKey: [ADMIN_EXERCISES_QUERY_KEY, queryParams],
    queryFn: () =>
      CustomAssetsService.getDuplicatedExercises(
        selectedType,
        page + 1,
        rowsPerPage,
        querySearchByName,
        parseInt(querySearchByCoachId) || null,
      ),
    placeholderData: keepPreviousData,
  });

  const { mutate: deleteDuplicateAsset } = useMutation({
    mutationKey: ["delete-duplicate-asset"],
    mutationFn: () =>
      CustomAssetsService.deleteAllDuplicateExercises(selectedType),
  });

  useEffect(() => {
    if (
      querySearchByName !== searchByName ||
      querySearchByCoachId !== searchByCoachId
    ) {
      const timer = setTimeout(() => {
        setQueryParams({
          assetName: searchByName,
          coachId: searchByCoachId,
          page: "0",
        });
      }, 300);

      return () => clearTimeout(timer);
    }
  }, [
    querySearchByCoachId,
    querySearchByName,
    searchByCoachId,
    searchByName,
    setQueryParams,
  ]);

  const handleChangePage = (event, newPage) => {
    setQueryParams({
      page: newPage.toString(),
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setQueryParams({
      perPage: event.target.value,
      page: "0",
    });
  };

  const handleTabChange = (event, value) => {
    setSearchByName("");
    setSearchByCoachId("");
    setQueryParams({
      type: value,
      page: "0",
      assetName: null,
      coachId: null,
    });
    navigate(
      ADMIN_EXERCISES_ROUTE +
        `?page=0&type=${value}&perPage=${queryParams.perPage}`,
    );
  };

  const handleCancelDeleteAllDuplicatesByType = React.useCallback(() => {
    setConfirmDeleteAllDuplicatesByTypeDialogOpen(false);
  }, []);

  const handleConfirmDeleteAllDuplicatesByType = React.useCallback(() => {
    deleteDuplicateAsset();
    setConfirmDeleteAllDuplicatesByTypeDialogOpen(false);
  }, []);

  const emptyRows = Array.from(new Array(rowsPerPage));
  return (
    <Paper className={s.root}>
      <Tabs
        className={s.tabs}
        value={selectedType}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleTabChange}
      >
        <Tab label="Library" value={ExerciseAssetsType.LIBRARY} />
        <Tab label="Private" value={ExerciseAssetsType.PRIVATE} />
      </Tabs>

      <Box className={s.container}>
        <Box className={s.searchContainer}>
          <TextField
            className={s.searchInput}
            variant="outlined"
            placeholder="Search records by name"
            value={searchByName}
            onChange={(e) => setSearchByName(e.target.value)}
          />
          {selectedType === ExerciseAssetsType.PRIVATE && (
            <TextField
              className={s.searchInput}
              variant="outlined"
              placeholder="Search records by coach id"
              value={searchByCoachId}
              onChange={(e) => setSearchByCoachId(e.target.value)}
            />
          )}
        </Box>
        <Button
          onClick={() => setConfirmDeleteAllDuplicatesByTypeDialogOpen(true)}
          variant="contained"
        >
          Delete {selectedType.toLocaleLowerCase()} duplicates
        </Button>
        <Typography className={s.summary}>
          {exerciseItems?.totalCount || 0} record
          {exerciseItems?.totalCount === 1 ? "" : "s"} found
        </Typography>

        <Table className={s.table} aria-label="simple table">
          {isFetching ? (
            <>
              <TableHead>
                <TableRow>
                  {Array.from({ length: 5 }).map((_, index) => (
                    <TableCell key={index}>
                      <Skeleton />
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {emptyRows.map((_, index) => (
                  <TableRow sx={{ height: 80 }} key={index}>
                    {Array.from({ length: 5 }).map((_, cellIndex) => (
                      <TableCell key={cellIndex} align="center">
                        <Skeleton />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </>
          ) : (
            <>
              <TableHead>
                <TableRow>
                  <TableCell>Video</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell align="center">Asset Id</TableCell>
                  <TableCell align="center">History</TableCell>
                  <TableCell align="center">No. Media</TableCell>
                  <TableCell align="center">No. Duplicates</TableCell>
                  <TableCell align="center">Sets</TableCell>
                  {selectedType === ExerciseAssetsType.PRIVATE && (
                    <TableCell align="center">Coach</TableCell>
                  )}
                  <TableCell align="center">Last Actions</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {exerciseItems.items.flatMap((e, index) => (
                  <ExercisesTableRow
                    exerciseInfo={e}
                    type={selectedType}
                    key={index}
                  />
                ))}

                {!exerciseItems.items.length && (
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={
                        selectedType === ExerciseAssetsType.PRIVATE ? 9 : 8
                      }
                    >
                      No exercises found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </>
          )}
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                colSpan={selectedType === ExerciseAssetsType.PRIVATE ? 9 : 8}
                count={exerciseItems?.totalCount || 0}
                rowsPerPage={rowsPerPage}
                page={Number(page)}
                showFirstButton
                showLastButton
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Box>
      <ConfirmActionDialog
        title={`Are you sure you want to delete exercise all ${selectedType.toLocaleLowerCase()} assets duplicates?`}
        open={confirmDeleteAllDuplicatesByTypeDialogOpen}
        onClose={handleCancelDeleteAllDuplicatesByType}
        onCancel={handleCancelDeleteAllDuplicatesByType}
        onConfirm={handleConfirmDeleteAllDuplicatesByType}
      />
    </Paper>
  );
}
