import { ElementType } from "../../editor/types/elements";
import { CustomAssetType } from "../../../constants";

import { useOptimisticUpdateMutation } from "../../../hooks/useOptimisticUpdateMutation";
import CustomAssetsService from "../../../services/CustomAssetsService";
import { InfiniteData } from "@tanstack/react-query";
import {
  CustomAssetDto,
  ICustomAssetDto,
  PaginatedListOfCustomAssetDto,
} from "@growth-machine-llc/stridist-api-client";
import { INFINITE_PAGINATED_DATA_UPDATERS } from "../../../utils/optimisticUpdate";
import { COMPONENT_LIBRARY_ASSET_LIST_QUERY_KEY } from "./useCustomAssets";
import { EXERCISE_LIBRARY_ASSET_LIST_QUERY_KEY } from "../../exercise-library/ExerciseLibraryExercisesList";
import { useCurrentUserId } from "../../../hooks/useCurrentUser";

export const getElementCustomAssetType = (element: any): CustomAssetType => {
  switch (element.type) {
    case ElementType.IMAGE:
    case ElementType.VIDEO:
    case ElementType.AUDIO:
    case ElementType.FILE:
      return (element.type as string).toUpperCase() as CustomAssetType;
  }
};

export const normalizeCustomAsset = ({ id, ...element }: any) => {
  const name = element.name as string;
  const content = JSON.stringify(element);
  const assetType = getElementCustomAssetType(element);

  return { name, assetType, content };
};

type CreateCustomAssetOptions = {
  isExercise: boolean;
  successMessage?: string;
};

export function useCreateCustomAsset({
  isExercise,
  successMessage,
}: CreateCustomAssetOptions) {
  const queryKey = isExercise
    ? EXERCISE_LIBRARY_ASSET_LIST_QUERY_KEY
    : COMPONENT_LIBRARY_ASSET_LIST_QUERY_KEY;
  const currentUserId = useCurrentUserId();
  return useOptimisticUpdateMutation({
    queryKey: [queryKey],
    mutationFn: CustomAssetsService.createCustomAsset,
    successToastMessage: successMessage ?? "Asset created.",
    optimisticUpdater: {
      updateFn: (
        oldData: InfiniteData<PaginatedListOfCustomAssetDto>,
        variables: ICustomAssetDto,
        id: number,
      ) => {
        const updatedOldData = isExercise
          ? INFINITE_PAGINATED_DATA_UPDATERS.filterOutItemByName(
              oldData,
              variables.name,
            )
          : oldData;

        return INFINITE_PAGINATED_DATA_UPDATERS.addItem(
          updatedOldData,
          new CustomAssetDto({
            ...variables,
            assetType: getElementCustomAssetType(variables.assetType),
            coachId: currentUserId,
            id,
          }),
        ) as InfiniteData<PaginatedListOfCustomAssetDto>;
      },
    },
    invalidateQueryOptions: {
      enabled: true,
    },
    exactQueryKey: false,
    successUpdater: {
      updateFn: (oldData, id, tempId) =>
        INFINITE_PAGINATED_DATA_UPDATERS.updateItemProperties(oldData, tempId, {
          id,
        }) as InfiniteData<PaginatedListOfCustomAssetDto>,
    },
  });
}
