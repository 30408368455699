import { useMutation, useQueryClient } from "@tanstack/react-query";
import BillingService from "../../../services/BillingService";
import { ADMIN_USER_QUERY_KEY } from "../UserPage";

export const useCreateSubAccountMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: BillingService.createSubAccount,
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: [ADMIN_USER_QUERY_KEY, { userId: variables.coachId }],
      });
    },
  });
};
