import CustomAssetsService from "../../../services/CustomAssetsService";
import { useOptimisticUpdateMutation } from "../../../hooks/useOptimisticUpdateMutation";
import { PaginatedListOfDuplicatedExerciseAssetDto } from "@growth-machine-llc/stridist-api-client";
import { ADMIN_EXERCISES_QUERY_KEY } from "../../admin/ExercisesTable";

export function useDeleteAdminExerciseAsset() {
  return useOptimisticUpdateMutation({
    queryKey: [ADMIN_EXERCISES_QUERY_KEY],
    mutationFn: CustomAssetsService.adminDeleteExerciseCustomAsset,
    optimisticUpdater: {
      updateFn: (
        oldData: PaginatedListOfDuplicatedExerciseAssetDto,
        id: number,
      ) => {
        return {
          ...oldData,
          items: oldData.items.filter((item) => item.id !== id),
        } as PaginatedListOfDuplicatedExerciseAssetDto;
      },
    },
    invalidateQueryOptions: {
      enabled: true,
      refetchType: "all",
    },
    exactQueryKey: false,
  });
}
