import clsx from "clsx";
import React from "react";
import { Menu, MenuProps, MenuItem, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { MoreHoriz } from "@mui/icons-material";
import { ConfirmActionDialog } from "../dialog/ConfirmActionDialog";
import { WorkoutEditExercise } from "../workout/WorkoutEditExercise";
import { WorkoutExercise } from "../workout/types";
import { EditorProgramContext } from "../new-editor/hooks";
import { WorkoutDrawer } from "../workout/WorkoutDrawer";
import { useDeleteAdminExerciseAsset } from "../component-library/hooks/useDeleteAdminExerciseAsset";

const useStyles = makeStyles(() => ({
  root: {},
}));

export interface ExercisesMenuProps
  extends Omit<MenuProps, "open" | "onClose"> {
  exercise: WorkoutExercise;
  exerciseId: number;
  jsonContent: string;
}

export function ExercisesMenu(props: ExercisesMenuProps) {
  const { className, exercise, exerciseId, jsonContent, ...other } = props;
  const { mutate: adminDeleteExercise } = useDeleteAdminExerciseAsset();

  const s = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [confirmExerciseDeleteDialogOpen, setConfirmExerciseDeleteDialogOpen] =
    React.useState(false);

  const handleOpen = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleCopyJSONContent = React.useCallback(() => {
    navigator.clipboard.writeText(jsonContent);
  }, [jsonContent]);

  const handleExerciseDelete = React.useCallback(() => {
    setAnchorEl(null);

    // TODO temporary disable confirmation to speed up delete actions
    setConfirmExerciseDeleteDialogOpen(true);
  }, [exercise, setConfirmExerciseDeleteDialogOpen]);

  const handleConfirmExerciseDelete = React.useCallback(() => {
    adminDeleteExercise(exerciseId);
    setConfirmExerciseDeleteDialogOpen(false);
  }, [exercise]);

  const handleCancelExerciseDelete = React.useCallback(() => {
    setConfirmExerciseDeleteDialogOpen(false);
  }, []);

  return (
    <>
      <IconButton onClick={handleOpen} size="large">
        <MoreHoriz />
      </IconButton>
      <Menu
        id={exercise.id.toString()}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        keepMounted
        onClose={handleClose}
        className={clsx(s.root, className)}
        {...other}
      >
        <MenuItem onClick={handleCopyJSONContent}>Copy JSON content</MenuItem>
        <MenuItem onClick={handleExerciseDelete}>Delete exercise</MenuItem>
      </Menu>

      <ConfirmActionDialog
        title={"Are you sure you want to delete exercise?"}
        open={confirmExerciseDeleteDialogOpen}
        onClose={handleCancelExerciseDelete}
        onCancel={handleCancelExerciseDelete}
        onConfirm={handleConfirmExerciseDelete}
      />
    </>
  );
}
